import { FaWrench, FaHammer, FaCalendarAlt, FaBookOpen } from 'react-icons/fa';
import { HiOutlineNewspaper } from 'react-icons/hi';

function ButtonList(FilterData) {
	return FilterData.map((document, index) => {
		switch (document.type) {
			case 'Ledger':
				return (
					<div className="doc-button" key={index}>
						<a href={document.link} className="ledger-btn">
							<HiOutlineNewspaper size={50} style={{ color: '#000' }} />
						</a>
						<p>{document.description}</p>
					</div>
				);
			case 'SMP':
				return (
					<div className="doc-button" key={index}>
						<a href={document.link} className="smp-btn">
							<FaWrench size={50} style={{ color: '#000' }} />
						</a>
						<p>{document.description}</p>
					</div>
				);
			case 'SOP':
				return (
					<div className="doc-button" key={index}>
						<a href={document.link} className="sop-btn">
							<FaHammer size={20} style={{ color: '#000' }} />
						</a>
						<p>{document.description}</p>
					</div>
				);
			case 'SSD':
				return (
					<div className="doc-button" key={index}>
						<a href={document.link} className="supplier-btn">
							<FaBookOpen size={50} style={{ color: '#000' }} />
						</a>
						<p>{document.description}</p>
					</div>
				);
			case 'Manual':
				return (
					<div className="doc-button">
						<a href={document.link} className="manual-btn">
							<HiOutlineNewspaper size={50} style={{ color: '#000' }} />
						</a>
						<p>{document.description}</p>
					</div>
				);
			case 'CILR':
				return (
					<div className="doc-button">
						<a href={document.link} className="manual-btn">
							<HiOutlineNewspaper size={50} style={{ color: '#000' }} />
						</a>
						<p>{document.description}</p>
					</div>
				);
			case 'LubeMap':
				return (
					<div className="doc-button">
						<a href={document.link} className="manual-btn">
							<HiOutlineNewspaper size={50} style={{ color: '#000' }} />
						</a>
						<p>{document.description}</p>
					</div>
				);
			case 'Calendar':
				return (
					<div className="doc-button">
						<a href={document.link} className="calendar-btn">
							<FaCalendarAlt size={50} style={{ color: '#000' }} />
						</a>
						<p>{document.description}</p>
					</div>
				);
			default:
				break;
		}
	});
}

export default ButtonList;
