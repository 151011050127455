import React from 'react';
import styled from 'styled-components';
import ButtonBg from '../layout/header/btn-bg.jpg';

const HomeButtonComp = ({ className, title, img, desc, link }) => {
	return (
		<a href={link} style={{ textDecoration: 'none' }}>
			<button className={className}>
				<img src={img} />
				<h3 style={{ background: `url(${ButtonBg})` }}>{title}</h3>
			</button>
		</a>
	);
};

const HomeButton = styled(HomeButtonComp)`
	z-index: 4;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: end;
	width: 24vw;
	height: fit-content;
	padding: 0;
	border-radius: 1rem;
	border: solid black 2px;
	transition: all 0.3s ease-in-out;
	background: none;
	margin: 20px;
	-webkit-box-shadow: 6px 4px 20px -1px rgba(0, 0, 0, 0.62);
	-moz-box-shadow: 6px 4px 20px -1px rgba(0, 0, 0, 0.62);
	box-shadow: 6px 4px 20px -1px rgba(0, 0, 0, 0.62);

	& img {
		height: 26vh;
		display: block;
		margin: auto;
	}

	.btn-desc {
		font-size: 22px;
		font-weight: 400;
		margin: 0;
	}

	& h3 {
		margin: 0 auto;
		background: white;
		width: 100%;
		padding: 10px;
		border-bottom-left-radius: 0.8rem;
		border-bottom-right-radius: 0.8rem;
		border-top: solid #000;
		color: #000;
		font-weight: bold;
		font-size: 30px;
		filter: brightness(1.2);
	}

	& img {
		width: 100%;
		border-top-left-radius: 1rem;
		border-top-right-radius: 1rem;
	}

	&:hover {
		transform: scale(1.01);
	}

	@media screen and (max-width: 480px) {
		width: 80vw;
		margin: 20px auto;
	}
`;

export default HomeButton;
