const ClevelandData = [
	{
		name: 'ACIES 2515',
		documents: [
			{
				type: 'Ledger',
				description: 'ACIES 2515 Mechanical Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1stmXiYWtVoyHcQ5d_8RKJhJGCTJDwK8i9cgVI0ZbGKw/edit#gid=361993437',
			},

			{
				type: 'Ledger',
				description: 'ACIES 2515 Controls Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1CBUIGmlHZtRdGL8uWB-WGQQaInwSf2-3_m55lRfl8U8/edit#gid=294972439',
			},
		],
	},
	{
		name: 'AMS 3015',
		documents: [
			{
				type: 'Ledger',
				description: 'AMS 3015 Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1UgoWN8NO_YQ6LuAcrs9If-Snu_AbDHMcX4hovqCSS0A/edit#gid=216160502',
			},
		],
	},

	{
		name: 'ENSIS 3015',
		documents: [
			{
				type: 'Ledger',
				description: 'ENSIS 3015 Ledger',
				link: 'https://docs.google.com/spreadsheets/d/16zkPQmE0jCpDK87reMoS-T7sqOv0pFS6aFK2-y4rERk/edit#gid=1187091305',
			},
		],
	},

	{
		name: 'Door Adhesive Cell',
		documents: [
			{
				type: 'Ledger',
				description: 'Door Adhesive Cell: Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1mSQETlGYAX1zfK_Qa66m2qb_79BClX0apMI1Av1vz2k/edit#gid=1174597877',
			},
			{
				type: 'SMP',
				description: 'SMP-1 - Allen Bradley Servo Motor Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1UlxnnMcjRZt6RTLlsg7R_oQpwG4U9p7nVE_BBUp_B4Q/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-2 - Hiwin Linear Rail and Bearing Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1UumumaRs5hLE4TbWXtsHKbCdLIvYMMy_98bm51EVAeA/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-3 - Piab Suction Cup Replacement',
				link: 'https://docs.google.com/spreadsheets/d/13Np9jRsHbA55fQ6c3TrJE_wnKki1nHnJTkfPSYR2Od4/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-4 - Piab Vacuum Pump Replacement',
				link: 'https://docs.google.com/spreadsheets/d/13Np9jRsHbA55fQ6c3TrJE_wnKki1nHnJTkfPSYR2Od4/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-5 - Keyence Wide FOV Monochrome Camera Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1_A4q_Q1mfoVKJmFBZeYH7sn8d0OHvB6rS90ODYeZEeM/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-6 - Banner Light Strip Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1YRLH1jwWAH-UyPoGXC0I2ZozCwL49bSt86qXEI0z-ak/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-7 - SMC Cylinder Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1mPQlXpKYIS_1d7NRc7TnP20YZSDlllQlSWZz4MatH9o/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-8 - Keyence Laser Sensor Replacement (Sta. 20)',
				link: 'https://docs.google.com/spreadsheets/d/1H1O-YEJAxWsZ7MiCec2dysXWr0h0sZaM6Q2GEXs6JPI/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-9 - Cleaning the Glue Dispence Nozzle (PURGE) Procedure (Sta. 040)',
				link: 'https://docs.google.com/spreadsheets/d/1paGzYQ5PmYTahIXPmVPG1KiDrEc1tmCsFQRhXgl220Q/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-10 - SMC Rodless Cylinder Replacement (Sta. 050)',
				link: 'https://docs.google.com/spreadsheets/d/1uY5DXoWPyNDhN8zwGYfew_e-t9s2kyN31fHpu9UvFX4/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-11 - Keyence Vision System Camera Replacement (Sta. 50)',
				link: 'https://docs.google.com/spreadsheets/d/17ttHyVWGwUBudDHLhhvMN6rPQISooqX-TKBZmxBrzXw/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-12 - Macron Dynamics Centering Actuator Replacement (Sta. 070)',
				link: 'https://docs.google.com/spreadsheets/d/1gMopSTtmnYUO7X_8hvdpzTVKLwQI4i7K2U_mUBGWia8/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-13 - Misumi Cam Follower Replacement (Sta. 70)',
				link: 'https://docs.google.com/spreadsheets/d/1cxqkIgb2WKudqYIw2VltEdOLUflO9ZQ3av1R3a8NtWA/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-14 - Derlin Standoff Replacement (Sta. 80)',
				link: 'https://docs.google.com/spreadsheets/d/16JfDAdWItREQ5aWJtmZWdjjpjlpJqE3fD_e00sHX5Dc/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-15 - SMC Long Stroke Precision Slide Table Replacement (Sta. 090)',
				link: 'https://docs.google.com/spreadsheets/d/1ZUKT8e9xwPAtM7-h-F8QPvUA-IAD0vlAK8_lH7yuAGw/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-16 - Side Guide Replacement (Sta. 90-1)',
				link: 'https://docs.google.com/spreadsheets/d/1WRKLwrl55rtzJfoLtOnwywTmPi4zncgKMGW5KC2ufZQ/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-17 - Glass Back Stop and Push Pad Replacement (STA. 090-05)',
				link: 'https://docs.google.com/spreadsheets/d/14_9bU5n7DvhNfL9QGmAtyYn8sTlc62AcxtVcjjCJjS4/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-18 - Conveyor Guide Replacement (Sta. 110))',
				link: 'https://docs.google.com/spreadsheets/d/15WoQmdmNDbaNkkn3_9Maw70RDwgecDZBh1AxLnpRy4o/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-19 - Side Guide Replacement (Sta. 110-01)',
				link: 'https://docs.google.com/spreadsheets/d/12gSV_wAeKFNJJc6vf6B9eTBVNzVbqZy9lhl4qd8YuAQ/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-20 - Gripper Finger Replacement (Sta. 110-2)',
				link: 'https://docs.google.com/spreadsheets/d/1at9pfpWPFGFRScrbM3wemmZmA3JEWZlH-SkOWFEjLvU/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-21 - SMC Pneumatic Gripper Replacement (Sta. 110-02)',
				link: 'https://docs.google.com/spreadsheets/d/1EHKmZktAiL1l2dGO70bJM3u0k74hedmRba5MoW9b-x8/edit#gid=55643247',
			},
			{
				type: 'SMP',
				description: 'SMP-22 - Gripper Finger Replacement (Sta. 110-2)',
				link: 'https://docs.google.com/spreadsheets/d/1wFKMzaVq-9Axfh5kTnaB9IY7urW9LDJJ8C3NmHtcvIs/edit#gid=55643247',
			},
			{
				type: 'SMP',
				description: 'SMP-23 - Festo Ballscrew Linear Actuator Replacement (Sta. 120-01)',
				link: 'https://docs.google.com/spreadsheets/d/19nnODi8Ougw5yZS0YrCqmvA-D9uLPAuLGKe25rCfoOk/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-24 - PFA Gripper Pad Replacement (Sta. 120-01)',
				link: 'https://docs.google.com/spreadsheets/d/1t0ai0JQfDQffOfj7wmj4IwbOffqHQgjTsqKH-ZXNedk/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-25 - Locate Block Replacement (Sta. 130)',
				link: 'https://docs.google.com/spreadsheets/d/1arpc57hX6tymSAQmdqXpcngyBy3SkeTLGE0vIgeg6H0/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-26 - Macron Dynamics Centering Actuator Replacement (Sta. 150-01)',
				link: 'https://docs.google.com/spreadsheets/d/1jMxWq9RZpf6qAn_o-Lyzp8I-toMMHK51xTBiJBThjb0/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-27 - Nesting, Centering, and Support Component Replacement (Sta. 150-01)',
				link: 'https://docs.google.com/spreadsheets/d/1P1wBWx3term2PlxMbcMd7G2d9KGhj6siNM2Fx8wTCNU/edit#gid=55643247',
			},

			{
				type: 'SMP',
				description: 'SMP-28 - Precision Shaft Replacement (Sta. 150-01)',
				link: 'https://docs.google.com/spreadsheets/d/1MfiF-ssdAC_5vCbXQqhgwGzkQC-IkMcBzHbrBbGoSys/edit#gid=55643247',
			},
			{
				type: 'SMP',
				description: 'SMP-29 - Festo Ballscrew Linear Actuator Replacement (Sta. 160-01)',
				link: 'https://docs.google.com/spreadsheets/d/1KxtCBelgnrZIAQ_FjL_K8Qq5-97m1mtQV128jAh5--w/edit#gid=55643247',
			},
			{
				type: 'SMP',
				description: 'SMP-30 - Misumi Flanged Linear Double Bushing Replacement (Sta. 170)',
				link: 'https://docs.google.com/spreadsheets/d/1W7UdqFeZ5BdS5bL5FGu8F3NyZce8BB988qrHeMdfYuc/edit#gid=55643247',
			},
			{
				type: 'SMP',
				description: 'SMP-31 - Press Pad Replacement (Sta. 170)',
				link: 'https://docs.google.com/spreadsheets/d/1tR40Xp3_XH2_rvMsp3d24VBP6OKaGuxgpVJMTBUU9Ag/edit#gid=55643247',
			},
			{
				type: 'SMP',
				description: 'SMP-32 - Misumi Linear Bearing and Shaft Replacement (Sta. 180)',
				link: 'https://docs.google.com/spreadsheets/d/1QSa0a_m63B6u1_VlLJ3R2gc0tq9B9jc-joOZcjkver4/edit#gid=55643247',
			},
			{
				type: 'SMP',
				description: 'SMP-34 - Glide-Line Conveyor Motor Replacement (Sta. 200)',
				link: 'https://docs.google.com/spreadsheets/d/1m3UdIbOeEGxRIKYXxo54HQqM7FExWLpnpOCGkfGlLyA/edit#gid=55643247',
			},
			{
				type: 'SMP',
				description: 'SMP-35 - Glide-Line Conveyor Drive Cartridge Replacement (Sta. 200)',
				link: 'https://docs.google.com/spreadsheets/d/1WQPPh9Ro6DlJ8P7LmJRpKSvHBozZJ-WLJRfNOMmKkK8/edit#gid=55643247',
			},
			{
				type: 'SMP',
				description: 'SMP-36 - Glide-Line Conveyor Drive Shaft Replacement (Sta. 200)',
				link: 'https://docs.google.com/spreadsheets/d/1mSIKOX4rtK0q_NjKWy5gHik4ZbZlWQeXOhxxB5y9XZA/edit#gid=55643247',
			},
			{
				type: 'SMP',
				description: 'SMP-37 - Glide-Line Conveyor Idler Pulley Replacement (Sta. 200)',
				link: 'https://docs.google.com/spreadsheets/d/12fZ4yqwrJe-4pKf_4kXcQlVTJ3lW9lvcUPYkencavlE/edit#gid=55643247',
			},
			{
				type: 'SMP',
				description: 'SMP-38 - Glide-Line Conveyor VTU Belt Replacement (Sta. 200)',
				link: 'https://docs.google.com/spreadsheets/d/1s3C98BP_5euswX8lyXwRleMP6JR3Mpc3BQOZ-6_A6gU/edit#gid=55643247',
			},
			{
				type: 'SMP',
				description: 'SMP-40 - Straub Foil Tape Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1_hNH__BfMBPqKbYNolHKnVRj_qg_XFuHKVaVh_6gZOw/edit#gid=55643247',
			},
			{
				type: 'SMP',
				description: 'SMP-41 - Straub Tape Threading Setup Procedure',
				link: 'https://docs.google.com/spreadsheets/d/14IOkeilInv6N1g6Q4vxe1oq4bNywSgGt-CUJXR0yGsU/edit#gid=55643247',
			},
			{
				type: 'SMP',
				description: 'SMP-42 - Straub VHB Tape Threading Setup Procedure',
				link: 'https://docs.google.com/spreadsheets/d/1TUtQ8kRVYipoJK-OxDivBdXPHOi1QSRUWm55zR9S_6Q/edit#gid=55643247',
			},
			{
				type: 'SMP',
				description: 'SMP-43 - Robot Recovery Procedure',
				link: 'https://docs.google.com/spreadsheets/d/1dZ3ox8Q1O5zPSE1-7QDaBnh3Vxz7Q2aKWL1DzwU9KDA/edit#gid=55643247',
			},
			{
				type: 'SMP',
				description: 'SMP-44 - Robot Positions Recovery Procedure',
				link: 'https://docs.google.com/spreadsheets/d/1x7Y7rKtEoRgjusWRI91kmpw1defZt0DnLzQYrp9Dk-A/edit#gid=55643247',
			},
			{
				type: 'SMP',
				description: 'SMP-45 - Elevator Recovery Procedure',
				link: 'https://docs.google.com/spreadsheets/d/12a-8_cYA1-dw6Didnr2_Sl8yUwp4ft-gXXQJvqvlkWw/edit#gid=55643247',
			},

			{
				type: 'SOP',
				description: 'C01 - Cleaning 020 Operator Station',
				link: 'https://docs.google.com/spreadsheets/d/1GHbRVTyBy54GGv9CTBAUaSqi9r8WgNLou07C98wf6os/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'C02 - Cleaning 030 Robot Plasma Treat',
				link: 'https://docs.google.com/spreadsheets/d/1j6ITdZpLDgn3vzvAHgWiioJj2Gc33ubqp4O76-Ni094/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'C03 - Cleaning 040 Robot Glue Dispense',
				link: 'https://docs.google.com/spreadsheets/d/1uJBD75I3MCoAo65k0jjqUwj3g1Skc_xkA_OliQOOnOQ/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'C04 - Cleaning 050 Glue Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1tfEpHmZUKIl01h_l4TBe46qt8ML5_Ta8A3VVg7Y5MG0/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'C05 - Cleaning 070 Part Locate',
				link: 'https://docs.google.com/spreadsheets/d/1QpyG_ayr6TViTlZc7eKWVNwkKPXW2cxUDFEyu6bKkkM/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'C06 - Cleaning 080 Glass Place',
				link: 'https://docs.google.com/spreadsheets/d/1OPvLV04er39441nPey06xgeeFxRuIga5elj3mwKdOEA/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'C07 - Cleaning 090 Glass Loading Conveyor',
				link: 'https://docs.google.com/spreadsheets/d/1Svr8ANXRtlUtvnonfewmEB1ersVVV9Frf9YC4NX6Y4A/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'C08 - Cleaning 100 Operator Station 2',
				link: 'https://docs.google.com/spreadsheets/d/17rgNtouE-izWOkPgon37ix9F2JNsRkBmspAUyUPJmMo/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'C09 - Cleaning 110 Door Bracket Conveyor',
				link: 'https://docs.google.com/spreadsheets/d/13HlXt9nBHV5eql3p7klZ2R0lKafxqLH1XKaYiKlzHgs/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'C10 - Cleaning 120 Backer & Bracket Pick',
				link: 'https://docs.google.com/spreadsheets/d/1PEUl37WD2mKkruUO4QkLpHewCHEF7DkE_tOuQEUjdLE/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'C11 - Cleaning 130 Transfer Nest',
				link: 'https://docs.google.com/spreadsheets/d/1x-zS-JeGhb4m3oBRd-7vXBCvFYmStRdsbuOnnU4vzAc/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'C12 - Cleaning 140 VHB Tape Application',
				link: 'https://docs.google.com/spreadsheets/d/1BWBdm3zV8akYBsoXuf7sjT96wvveZzc_eRO_9epHOAE/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'C13 - Cleaning 145 Tape Application Robot',
				link: 'https://docs.google.com/spreadsheets/d/1Wm1GIOiYj1OGHuM419af29vxhQj2Dw33huF4iXB8Rhs/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'C14 - Cleaning 150 Centering Nest',
				link: 'https://docs.google.com/spreadsheets/d/1LMckCaUavL7SSRy44nABsLAblJGndrduTDFJ8suvAhQ/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'C15 - Cleaning 160 Backer & Bracket Place',
				link: 'https://docs.google.com/spreadsheets/d/170zKNx8carapmgXE1H2JzSxzBoLAuQDVtLKrFM_d6sc/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'C16 - Cleaning 170 Press Station',
				link: 'https://docs.google.com/spreadsheets/d/13GWwGpSdJv7plCfwW9rdiMC7I7yG_o6KXG9hFdFN4Hc/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'C17 - Cleaning 180 Laser Mark',
				link: 'https://docs.google.com/spreadsheets/d/1Q0gu76-840ffwUUvGen2qPQpM2rZEkSdkK6zryKnoOY/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'C18 - Cleaning 200 Main Line Conveyor',
				link: 'https://docs.google.com/spreadsheets/d/1DVU7fgrtLHy4ywJPJq9hf32wXp4y79MhPKOPCjezifE/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'C19 - Cleaning 500 Guarding',
				link: 'https://docs.google.com/spreadsheets/d/1A_IjC6YrOsruBvz0JxwzkxmC_TFVDgJ1UuM5IXuM944/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'I01 - Inspecting 020 Operator Station',
				link: 'https://docs.google.com/spreadsheets/d/1rOnwOBEKQbAfApb3kcOQRv-QGPsm172BKi6ISjwz9TI/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'I02 - Inspecting 030 Robot Plasma Treat',
				link: 'https://docs.google.com/spreadsheets/d/1yu19Csak4FsHbfrumiK7BT5P00PVWi388mdR-CUvDr4/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'I03 - Inspecting 040 Robot Glue Dispense',
				link: 'https://docs.google.com/spreadsheets/d/1QBCanpcaFaQYPIREuCsI-C53cFTA7bxf7uwgK5972bA/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'I04 - Inspecting 050 Glue Inspection',
				link: 'https://docs.google.com/spreadsheets/d/109TfdYY9U2RCSjweW6-mzYHPm63C5CMvMzxzf9u7bFM/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'I05 - Inspecting 070 Part Locate',
				link: 'https://docs.google.com/spreadsheets/d/1sZJP8E89uBPDrlZwUI4oNvVu6sp-6Mvha0MMqBNcT2o/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'I06 - Inspecting 080 Glass Place',
				link: 'https://docs.google.com/spreadsheets/d/10HumvQfMatemrX7osS9qbDL2ZuWaQWDUTi2tJIhfItk/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'I07 - Inspecting 090 Glass Loading Conveyor',
				link: 'https://docs.google.com/spreadsheets/d/1C4_xdqa-Rl4uu_5o98PrGXgrV27jG3HPu4f36NgORy8/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'I08 - Inspecting 100 Operator Station 2',
				link: 'https://docs.google.com/spreadsheets/d/10O_H-gVfNI8zeLoCBktJDbh1WUNmjiQZfcNkH-YEMvo/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'I09 - Inspecting 110 Door Bracket Conveyor',
				link: 'https://docs.google.com/spreadsheets/d/15Q3mdon-FLo4jl2gbFaZhidcZU6wFSMSb9VxqYi5bpU/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'I10 - Inspecting 120 Backer & Bracket Pick',
				link: 'https://docs.google.com/spreadsheets/d/1p4w0hseeajJIljvCJGzCQswGXx9DFYX-V8s5r9AVxiU/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'I11 - Inspecting 130 Transfer Nest',
				link: 'https://docs.google.com/spreadsheets/d/1aYaSYOE4-YqFbFJeIHD-TEMGx03hZASvQ6jcwRni-Hk/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'I12 - Inspecting 140 VHB Tape Application',
				link: 'https://docs.google.com/spreadsheets/d/1BMtwQkn1P3dlI9RbO4MQK0_Fr6W8vtGLAJ3PC_fcrTg/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'I13 - Inspecting 145 Tape Application Robot',
				link: 'https://docs.google.com/spreadsheets/d/1FwjEIP2palG8nw6RciZOxDdb-Pd6w12MGZgbWjSPmsM/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'I14 - Inspecting 150 Centering Nest',
				link: 'https://docs.google.com/spreadsheets/d/1Ndn8on8DdYch0Uu1vQTm-uzOfttGaR2OzSmXFET3DH8/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'I15 - Inspecting 160 Backer & Bracket Place',
				link: 'https://docs.google.com/spreadsheets/d/1Yx6S0qziD8yvghSKwxMqgWP4EFhf53C-vnnrxgv9eVo/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'I16 - Inspecting 170 Press Station',
				link: 'https://docs.google.com/spreadsheets/d/1hKN_0_7B5yXuIJQmRc2n53IFvqmc84Rr28P8hzODuxA/edit#gid=0',
			},

			{
				type: 'SOP',
				description: 'I17 - Inspecting 180 Laser Mark',
				link: 'https://docs.google.com/spreadsheets/d/1_OnCGl0jyFQ-NYSKRv8ko1yFxB8Aoip7g3hgd58GHsM/edit#gid=0',
			},

			{
				type: 'SOP',
				description: 'I18 - Inspecting 200 Main Line Conveyor',
				link: 'https://docs.google.com/spreadsheets/d/1qPIOMm7_dJq-imjqZBkVjTKQGD0PtMTHakcTJtm6xB8/edit#gid=0',
			},

			{
				type: 'SOP',
				description: 'L01 - Refilling Hiwin Linear Carriage Cartridge',
				link: 'https://docs.google.com/spreadsheets/d/1iGOx5LJzWe8PO1P5sOWFED2l6rNL_6h2BraaAKdKyjc/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'R01 - Retightening 030 Robot Plasma Treat',
				link: 'https://docs.google.com/spreadsheets/d/1V4M3ir0GnUcBOHabBNF0gYpMDMpgKrltGO6fgDlg-sI/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'R02 - Retightening 040 Robot Glue Dispense',
				link: 'https://docs.google.com/spreadsheets/d/1KsV2F0OVW1XoNun_Pp9JQY_U6-7xRMhXrssQekk8Ajg/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'R03 - Retightening 050 Glue Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1CuIQ1HBFCTTJ4L693Xt4a4eMfFLVwhbGYMIbXZBj4rQ/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'R04 - Retightening 070 Part Locate',
				link: 'https://docs.google.com/spreadsheets/d/1FqektjKH41OjOT1hDer4UdXgWAQY0LpRVYefDo9tr5s/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'R05 - Retightening 080 Glass Place',
				link: 'https://docs.google.com/spreadsheets/d/1QY_BwdmqO-IOqpuv6bE6HadQikF8VnTga4DJ3jFhuZ4/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'R06 - Retightening 090 Glass Loading Conveyor',
				link: 'https://docs.google.com/spreadsheets/d/1X9Cy8A3ENBwry8YrjBytYRu4hlj1BV1BWjdyy8symQw/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'R07 - Retightening 110 Door Bracket Conveyor',
				link: 'https://docs.google.com/spreadsheets/d/1xC5evH8TKyShGpCTOoN1A4YB6hXlg6h31mWhQXFC2Mc/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'R08 - Retightening 120 Backer & Bracket Pick',
				link: 'https://docs.google.com/spreadsheets/d/1sWqzFdCf4OdYPeA4SNCDnv9BnmdKv6rHh0UgEKa7dVw/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'R09 - Retightening 140 VHB Tape Application',
				link: 'https://docs.google.com/spreadsheets/d/1ynjHq34GSvo9w08LNlon1izvcPKGhWLwa-BeUx3hnNE/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'R10 - Retightening 145 Tape Application Robot',
				link: 'https://docs.google.com/spreadsheets/d/1Kd2iAVf0QGeqLarLf96ywvPsMd972zHkqnaViRvvGRI/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'R11 - Retightening 160 Backer & Bracket Place',
				link: 'https://docs.google.com/spreadsheets/d/1uaAy1yPoEqWHLqiSTd06cEqjSHGKYpuHpRGngiH_edQ/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'R12 - Retightening 200 Main Line Conveyor',
				link: 'https://docs.google.com/spreadsheets/d/1xRA18vdRn4yOg_RYjtUCHPX0iuvKkvpCHKlew0v_vGE/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'S01 Test E-Stop Buttons',
				link: 'https://docs.google.com/spreadsheets/d/15EzICzA29F6KtQukz3eGv7_lGyjxGcy2Og0i45qMjy0/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'S02 Fortress Door Interlock Safety Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1DWgau8HvBHap2372CQgy4r98zHyI9uS8iH4VsLzZj7A/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'S03 Guard Locking Switch Safety Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1-wmRFdBoVste8XryMGzVR0y9WF3sMZqsGNiR6AMYuVU/edit#gid=0',
			},
			{
				type: 'SOP',
				description: 'S04 Keyence Light Curtain Safety Inspection',
				link: 'https://docs.google.com/spreadsheets/d/10RpE-NbY0KeLqp9NfWZ315cCIMf6xcZ7hV5_bTUxFGQ/edit#gid=0',
			},

			{
				type: 'Calendar',
				description: 'Door Adhesive Cell AM Calendar',
				link: 'https://docs.google.com/spreadsheets/d/15s0rWda0VNobBjcmHOb_nRcUj-Dlhn_9AL-_z_n-cM8/edit#gid=1155415262',
				//locked
			},
		],

		//locked
	},

	{
		name: 'Bce Smart 220',
		documents: [
			{
				type: 'Ledger',
				description: 'Bce Smart 220 Ledger',
				link: 'https://docs.google.com/spreadsheets/d/136OWTgRJdLrlYH_AMLcTfxMU5kd5mLhuMKPycHCSJnE/edit?ts=60759291#gid=1797294293',
			},

			{
				type: 'SMP',
				description: 'Bce Smart 220 SMPs',
				link: '#',
			},
		],
	},

	{
		name: 'eP0520',
		documents: [
			{
				type: 'Ledger',
				description: 'eP0520 Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1CzXxIaGlpGyH9HpOZusiG6ncpa5ePvnraaxdKc3fL6A/edit#gid=1797294293',
				//locked
			},

			{
				type: 'SMP',
				description: 'eP0520 SMPs',
				link: '#',
			},
		],
	},
];

export default ClevelandData;
