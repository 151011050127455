//packages
import styled from 'styled-components';

// icons
import logo from './its-logo.png';
import poweredBy from './powered-by-2.png';
import { BsFillTelephoneFill } from 'react-icons/bs';

// footer content and logic
const FooterComp = ({ className }) => {
	const link = 'https://www.interactivetrainingsystems.com';
	return (
		<footer className={className}>
			<div className="footer-left">
				<a href={link}>
					<img className="footer-logo" src={poweredBy} alt="logo" />
				</a>
			</div>
			<div className="footer-right">
				<h2>Whirlpool QuickResponse&trade; Library</h2>
			</div>
		</footer>
	);
};

// footer styling
const Footer = styled(FooterComp)`
	z-index: 100; // layer footer above anything else
	display: flex; // organized left-right
	justify-content: space-between; // footer-left and footer-right placed on opposite sides
	position: fixed; // stick in place
	bottom: 0; // stick to bottom of page
	width: 100%; // width of entire page
	height: var(--footer-height);
	min-height: 48px;
	background: var(--footer-background);
	border-top: solid var(--secondary);

	// left section
	.footer-left {
		display: flex;
		margin: auto 1rem;

		// powered by text
		.powered-by {
			margin: auto 0;
			padding: 5px;
			font-weight: bold;
		}

		// lower-left logo
		.footer-logo {
			height: 100%;
		}

		& a {
			height: 85%;
		}
	}

	// right section
	.footer-right {
		display: flex;
		margin: auto 1rem;
		color: #000;

		& h2 {
			font-weight: 700;
		}

		// footer right buttons
		& button {
			margin: auto 0.4vw;
			height: 90%;
			width: 3.5vw;

			// footer right buttons hover
			&:hover {
				transform: scale(1.05);
				filter: opacity(0.8);
			}
		}
	}

	@media screen and (max-width: 480px) {
		background: var(--primary);
		position: relative;
		z-index: 1;

		.footer-left {
			padding: 10px;
			margin: 0;
		}

		.footer-right {
			margin: 0;
			text-align: center;
			& h2 {
				font-size: 18px;
				margin: auto;
			}
		}
	}
`;

export default Footer;

// <Footer />
