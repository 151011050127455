const TulsaData = [
	{
		name: 'Electric Oven Cavity Line Station 115',
		documents: [
			{
				type: 'Ledger',
				description: 'Electric Oven Cavity Line Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1CtH7evSM9IXPvwlFz1yjzK8oS60T5apJvoFAwJTAqbI/edit#gid=408877832',
			},

			{
				type: 'SMP',
				description: 'SMP-1 - EOCL - STA 115 - Outer Fold Bracket Cylinder Replacement SMP',
				link: 'https://docs.google.com/document/d/1QfLU2U04wTj_WAsJxOP7MLVYweKyhMbA1OKGXTBMkFg/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-2 - EOCL - STA 115 - Outer Fold Bracket Assembly Replacement SMP',
				link: 'https://docs.google.com/document/d/1WOmDxGn1EDxy1zdUwmgIdAeY4DO9zNF5Y7yx9kFqkJk/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-4 - EOCL - STA 115 - Core Rotary Actuator Replacement SMP',
				link: 'https://docs.google.com/document/d/1XTsF6caB9yO9jYhfQkqPYCg2r8rkUmMjRef5LpiBkes/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-5 - EOCL - STA 115 - Wrap Support Bracket Cylinder Replacement SMP',
				link: 'https://docs.google.com/document/d/12i_iIUymYwtl6prQGhE6m1V7eIAvmqhPkOLnzAxBqUY/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-6 - EOCL - STA 115 - Core Rebuild Replacement SMP',
				link: 'https://docs.google.com/document/d/1GFpWf5wb6khRcISWeNYlu2VfCZ3pLykbk4ntFMM3ga4/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-7 - EOCL - STA 115 - Crowder Cylinder Replacement SMP',
				link: 'https://docs.google.com/document/d/1sdm9llw5trsH048Oeyo50e3KswPe80wDGw1I2j3iVNg/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-8 - EOCL - STA 115 - Crowder Bearing Replacement SMP',
				link: 'https://docs.google.com/document/d/1InDP4bn10JS0iFkPc6IpPHYz9s7-5h7Q3zFEj0ycG_w/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-9 - EOCL - STA 115 - Core Lift Cylinder Replacement SMP',
				link: 'https://docs.google.com/document/d/1if1Qyt6eRBs7qGtPaa-WUWQ6m52AbvSZLnxkmny-hEE/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-10 - EOCL - STA 115 - Core Lift Linear Bearing Replacement SMP',
				link: 'https://docs.google.com/document/d/1vtMLT1pTz_3aW1qV6pDYNvRgeDToByMh5IDbCQ4mUx0/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-11 - EOCL - STA 115 - Weld Gun Cylinder Replacement SMP',
				link: 'https://docs.google.com/document/d/1rnu7vmxcjwBMSYh-oc45d-8mUaqF9HCdVpGpDgmSQeo/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-12 - EOCL - STA 115 - Weld Shunt Replacement SMP',
				link: 'https://docs.google.com/document/d/1sWjklS-4hUF39gEyEaFyWsKTInHq1boL0cztzZaeG08/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-13 - EOCL - STA 115 - Joggle-Fold Tooling Replacement SMP',
				link: 'https://docs.google.com/document/d/1nCWk3pp7e3te9FY41C-5x-DzSz58M94ZJqYJYhNo4j0/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-15 - EOCL - STA 115 - Hydraulic Motor-Pump Coupling Replacement SMP',
				link: 'https://docs.google.com/document/d/1Fz-QJqcHR93wfHNladKoilkpznZQIXjILoy53LLN_WM/edit',
			},
		],
	},

	{
		name: 'Coldwater Vytek FC55',
		documents: [
			{
				type: 'Ledger',
				description: 'Coldwater Vytek FC55 Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1jZdbYhEJe_t7-iVUyMc0CZIADn_xpyo3BH5J1LhMLUE/edit?usp=sharing',
			},
		],
	},

	{
		name: '400A ET9010 ML Press',
		documents: [
			{
				type: 'Ledger',
				description: '400A ET9010 ML Press Ledger',
				link: 'https://docs.google.com/spreadsheets/d/12-Ez050UyStrf0dCV6hqi4J_5YTpUzJBCmYuqx8xBzA/edit#gid=657824894',
			},
		],
	},

	{
		name: 'DT Engineering System',
		documents: [
			{
				type: 'Ledger',
				description: 'ET9743 Form Station 1 Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1O74z0Yhn7n1UXB6iON5WpfdSIc-rmqm98NZalEAajU4/edit?pli=1#gid=2058701870',
			},
			{
				type: 'Ledger',
				description: 'ET9744 Form Station 2 Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1BajqQcVncwmDFwV7fPQGaqOKwU7n_diPN4wMCfguzmo/edit#gid=2058701870',
			},
			{
				type: 'Ledger',
				description: 'ET9746 Join Station Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1M_t53ut0A6ZigcfiwpDn6gPaRFsdm92xa2m-cPApObM/edit#gid=480626502',
			},
			{
				type: 'Ledger',
				description: 'ET9738 Wrapper Press 1 Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1WDYhp3AKQerfS8kTY2w-XGu2WDePqdRZU6UHBV3SSG8/edit#gid=848251433',
			},
			{
				type: 'Ledger',
				description: 'ET9739 Wrapper Press 2 Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1NEJU7zTifYASkX7jqQNuedDgHNDUo0FKFH2BeK75Ljk/edit#gid=848251433',
			},
			{
				type: 'Ledger',
				description: 'ET9741 Wrapper Press 4 Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1LN9h7Bylcv2bE1u6-oiBR3p-mtlj6_5fdJXWUSVVEO4/edit#gid=886293508',
			},
			{
				type: 'Ledger',
				description: 'ET9736 Wrapper Buffer Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1IjfcBBLSQblyxHO98V4WDnP60f_JvG_UzFSuo6aq5Tk/edit#gid=886293508',
			},
			{
				type: 'Ledger',
				description: 'ET9756 Back Buffer Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1H8XxQjo8M7ig5g8LrutDv22RfBCnoVrNl6Sur4axeiI/edit#gid=848251433',
			},
			{
				type: 'Ledger',
				description: 'ET9758 Back Press 1 Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1HjWg2hr3ul7kd8JwAGdybnWuVwdJ6gZ7dNx8FVlFBFs/edit#gid=848251433',
			},
			{
				type: 'Ledger',
				description: 'ET9759 Back Press 2 Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1fkJQN5av0-bVcvHTkOsZEWlL7lSHMJlb10s2_XSaCdA/edit#gid=848251433',
			},
			{
				type: 'Ledger',
				description: 'ET9753 Back Straightener-Shearer Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1-pu_VYdx8fMc5iiMmse_ixW_gtE_C5oAgW7Z6Ocwo7o/edit#gid=848251433',
			},
			{
				type: 'Ledger',
				description: 'ET9733 Wrapper Straightener-Shearer Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1P3kTBMX0fOsuxp9uFdUqCULM_m8Dpk_wd93nJZbwg0Y/edit#gid=1185292723',
			},
			{
				type: 'Ledger',
				description: 'ET9752 Back Uncoiler Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1XAsI_hBiVp-MkZCgj-bFmTNaWdqUi9J5vDFAZgPPlVA/edit#gid=848251433',
			},
			{
				type: 'Ledger',
				description: 'ET9732 Wrapper Uncoiler Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1uKZ8I2CqKr9hFNi2BBuiYMd7mh03sNtSSqey6pSPRJc/edit#gid=514146485',
			},
			{
				type: 'Ledger',
				description: 'ET9757 Back Line Dietronic Lubricator Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1hbXfVsf-GZbpxGUsthbjRqsfCrC3R9zUXyTORG310hQ/edit#gid=1642220759',
			},
			{
				type: 'Ledger',
				description: 'ET9737 Wrapper Line Dietronic Lubricator Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1HcowNMgj4QP2XScdyHJkDZXBOi771Eco3swg2Lr2CWE/edit#gid=1642220759',
			},
			{
				type: 'Ledger',
				description: 'ET9748 Back Part Conveyance Ledger',
				link: 'https://docs.google.com/spreadsheets/d/135i__B3xzFrGHVz8tFBmieLg0GOlc9-Gcylzmih3Kj8/edit#gid=761188959',
			},
			{
				type: 'Ledger',
				description: 'ET9749 Exit Conveyor Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1MLTE4PZXw-wlJNWRJA7NF6Fe7qSZy2yIjkAu_g_N-oA/edit#gid=761188959',
			},
			{
				type: 'Calendar',
				description: 'EOC 3 Cavity Line AM Calendar (Wrapper Line)',
				link: 'https://docs.google.com/spreadsheets/d/150-_ZVMi6VqdCszEQShb7gdvhtZy-JeKpoHeln3QNrY/edit#gid=1685654886',
			},
			{
				type: 'Calendar',
				description: 'EOC 3 Cavity Line AM Calendar (Back Line)',
				link: 'https://docs.google.com/spreadsheets/d/12DzB3Uuvq2wTKJooEsgeDxbDmg-vEAftewO4niKEPK0/edit#gid=668593593',
			},
			{
				type: 'LubeMap',
				description: 'EOC 3 Cavity Line Lube Maps (Wrapper Line)',
				link: 'https://interactivetrainingsystems.com/whirlpool/Tulsa/DTE_System/Back_Line_Lube_Maps_1.php',
			},
			{
				type: 'LubeMap',
				description: 'EOC 3 Cavity Line Lube Maps (Back Line)',
				link: 'https://interactivetrainingsystems.com/whirlpool/Tulsa/DTE_System/Back_Line_Lube_Maps_1.php',
			},
		],
	},

	{
		name: 'Pyrolytic Booth',
		documents: [
			{
				type: 'Ledger',
				description: 'Pyrolytic Booth Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1mkD3pv4-CEChoWTUOmJub3jIjaPUE1O1UpliE2APrmI/edit?pli=1#gid=2058701870',
			},
		],
	},

	{
		name: 'Eagle Press 330 Ton Press',
		documents: [
			{
				type: 'Ledger',
				description: 'Eagle Press Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1nsFk3jL1aIJY0AE-VRvj-cRGGB1U8YmCCZV0ixfKRUY/edit#gid=926335867',
			},
			{
				type: 'SMP',
				description: 'Standard Maintenance Procedure (SMP) Template',
				link: 'https://docs.google.com/presentation/d/12JIkZe-2d10QKbwR5_Ll5LxuMqK9lAcTsyl0vhQlCZ8/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP1 Flywheel Cylindrical and Spherical Bearing Replacement (FINAL)',
				link: 'https://docs.google.com/presentation/d/1_9vhfEhcBJFd85ypjHhd340TSdEIBXlekxYDaynTWKw/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP2 Clutch-Brake Replacement (FINAL)',
				link: 'https://docs.google.com/presentation/d/1E2q8umSUe0mnG8-Jn_M4dOWUa8SvS2Nr0-PjZ4ZNluo/edit#slide=id.g12630d35245_1_0',
			},
			{
				type: 'SMP',
				description: 'SMP3 Main Motor Replacement (FINAL)',
				link: 'https://docs.google.com/presentation/d/1ngdoDiHRh4cGP2qLih1i2_hqVTDdXKtJD2lmX7xxakA/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP4 Flywheel Belt Replacement (FINAL)',
				link: 'https://docs.google.com/presentation/d/1dt_zMq1WhUUpnG6oO6LLGQHQqDhss5Q-iuDoSSzUmXI/edit#slide=id.g1179ab1d73d_1_0',
			},
			{
				type: 'SMP',
				description: 'SMP5 Ram Leveling (FINAL)',
				link: 'https://docs.google.com/presentation/d/1WNAFwl1Omzzo0J4RU2_NkmsrP5HyVtcFLctlAh8xcvU/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP6 Overload Valve Replacement (FINAL)',
				link: 'https://docs.google.com/presentation/d/1_tQJHOFQJS-49AliIWZ_2jrPt_LSCjHAfvUO00UiQ3U/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP7 Shut Height Adjustment Gearbox/Motor Replacement (FINAL)',
				link: 'https://docs.google.com/presentation/d/1qt59ywgIcyymIUx86TivdfX4HVjPtIt3MUQkBtefemo/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP8 Procedure if Press Gets Stuck on Bottom (Hydraulic Nut Operations) (FINAL)',
				link: 'https://docs.google.com/presentation/d/1cd8B5HfaAVN_qMW5snqooALM5sgqhxZkpr0lbEiDEu0/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP9 Tonnage Monitor Set-up Procedure (FINAL)',
				link: 'https://docs.google.com/presentation/d/133Wf6D-OXPpw5CD1rr-emHeYXqRisPJGma8MVjcfbOE/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP10 Pump/Motor Replacement - Hydraulic HPU (FINAL)',
				link: 'https://docs.google.com/presentation/d/1UsmAnmO6X1SO0SIkKVxjWm0JRHxvMEViTQakv128h_M/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP11 Ram Encoder Replacement / Set-up (FINAL)',
				link: 'https://docs.google.com/presentation/d/1g-SiAf6Qc3z3OdOivPTIi3Ccr5HpPEx1dIzPbMG_tBU/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP12 Lube Pump Replacement (FINAL)',
				link: 'https://docs.google.com/presentation/d/1gGeN3_P1mVqsBT733pjY29H-OO_vho9i2cx4bBKTrwY/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP14 JIT Pump and Valve Replacement (FINAL)',
				link: 'https://docs.google.com/presentation/d/1pO1ogY47ei8T-fTft1iq1ENMRrRiPl8Roy2GuMT0cBU/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP15 Replacement and Adjustment of Press Gibs (FINAL)',
				link: 'https://docs.google.com/presentation/d/1wIMDt_zQgeEJOKdaj7JVQYvycmKZYz_qH4cxGTP_4Ww/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP16 V-Belt Tensioning Procedure (FINAL)',
				link: 'https://docs.google.com/presentation/d/1xyKPx2W4D44a3FrpsjdS_6gTZ3bcST4zgT-c2sG-chU/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP19 Priming the Lube System (FINAL)',
				link: 'https://docs.google.com/presentation/d/1yku8ZzYziZZGUM7-7tU3HeG482v54KKjmi0UKTVEaFM/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP20 Lube Inspection of the Main Eccentric and Intermediate Gear Chambers (FINAL)',
				link: 'https://docs.google.com/presentation/d/1UJMOUYjeNwsMwAcpwSLOanWCRkvQpFlaYity-neiJZ8/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP21 Overload System Reset and Purge (FINAL)',
				link: 'https://docs.google.com/presentation/d/1F_nwHmLMRZIQihZrIryJDWt6sYtw3Zn6YzUVfw7l6c4/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP22 Dumping Air Out of Counterbalance Tank and Cylinder (FINAL)',
				link: 'https://docs.google.com/presentation/d/1f90sAyMEiEOdGp1-cp3WcibZL7mx0Rn1OyMXuYxub_Y/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP23 Press Start-up Procedure (FINAL)',
				link: 'https://docs.google.com/presentation/d/1vmHP9F_Oyc-hK-Uz4fIfzLiMzK02aCPkd1aNSFmJLGc/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP24 Run Overload in Manual and Automatic (FINAL)',
				link: 'https://docs.google.com/presentation/d/1l6XsB8ged4hxMosW-tvLjhMqGmf7_jnDyI6N4N3Q3s0/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP25 Bleeding Pressure OFF Overload System Cylinder (FINAL)',
				link: 'https://docs.google.com/presentation/d/1y-1FvJL_zsZYD4ds0-C3nqZhpIKvtkIy-GdD6rPG-Ys/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SOP',
				description: 'SOP Start Sheet',
				link: 'https://docs.google.com/presentation/d/1ho6D3zQ7Sgm68vLQ3jJVYgWMuFON_M5xY0PboJSFaRM/edit?ts=60d07f95#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP C01 - Clean Orttech Panel on Top of Press',
				link: 'https://docs.google.com/presentation/d/1aIS_xQABqBLcT_cIAupOigX2hG1nncjBdz7Lv-mDH9c/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP C02 - Clean Bolster and Lower Clamps',
				link: 'https://docs.google.com/presentation/d/1VZ4515yuKNY6XsZWVlIbst_NUmRRMYGpifWj_ycurrg/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP C03 - Clean Overload Tank and Valve on Side',
				link: 'https://docs.google.com/presentation/d/1ese564cOgtKYNghpPIg8Vnf0k21cwwhg2ztVCy03X6M/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP C04 - General Press Cleaning',
				link: 'https://docs.google.com/presentation/d/1rVHP2gDnKUIfbYZhspDZ6P1o_1dFHsO1owJPfkBLfaM/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP C05 - Clean Hydraulic Power Unit',
				link: 'https://docs.google.com/presentation/d/1UBU3wlcZC21sb1X5xQkj0oMNsq23Pi0dUHYsij1ihAU/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP C06 - Clean HMI Panel and Pedestal',
				link: 'https://docs.google.com/presentation/d/1AoxhvtuF_RYGhoQ-uRwc8Gj6rqFvaNYumTUjy86rdAk/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP C07 - Clean Main Electrical Panel',
				link: 'https://docs.google.com/presentation/d/1gXW2AgODvYZgtF9nLPaiiJoskFx13DeWFHIZIqsFCtI/edit#slide=id.g849f23443e_0_17%20target=',
			},
			{
				type: 'SOP',
				description: 'SOP C08 - Clean Mechanical Control Panel',
				link: 'https://docs.google.com/presentation/d/1atgtBrSPNjEKDuobdgJoNfnY0BdAQeu_LkN9z50gAz0/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP I01 - Inspect Orttech Control Panel on Top of Press',
				link: 'https://docs.google.com/presentation/d/1ib8lTdJJx22rTrlm0MfgjdYR7BMlEYShRVoEF0_aUgI/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP I02 - Inspect Inside of Mechanical Cabinet (Floor)',
				link: 'https://docs.google.com/presentation/d/1rN68YPci8TKOveK5eF4mk3RhXVE2O6G0-siDbbY3u-s/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP I03 - Inspect Gibs on all Four Corners',
				link: 'https://docs.google.com/presentation/d/1WHDUKRsIriP26SnLtokZO9WcMJ26O8TnyYUy5zcUKaw/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP I04 - Inspect Overload Tank and Valve on Side',
				link: 'https://docs.google.com/presentation/d/1spyianBldLYA7p3r_qKsQ5vv2ETonypWFuQ7aP5kz9s/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP I05 - Inspect Clutch/Brake Cover (Mezzanine)',
				link: 'https://docs.google.com/presentation/d/1rv9vrMUUGg3ePLmibyE5w09bfAUlGQRFMmRhv8hnEbY/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP I06 - Inspect Main Lube System Tank (Basement)',
				link: 'https://docs.google.com/presentation/d/1ILraFGqVF7eYsDE96Ti0B6AOa5kg5kUIl7JaZjLK0AE/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP I07 - Inspect Match Marks on Press',
				link: 'https://docs.google.com/presentation/d/151mPOpYBFJyv8Btibo0XKIP7RyY7K3NJmq3FDpdY5pU/edit#slide=id.g117897807de_0_0',
			},
			{
				type: 'SOP',
				description: 'SOP I08 - Inspect Hydraulic Power Unit',
				link: 'https://docs.google.com/presentation/d/13RniEp6Oe9BPbpz8OQyp24D7Jy80Yrl9BXJvUTXQ_G0/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP L01 - Auto Lube System Refill',
				link: 'https://docs.google.com/presentation/d/1ikq82ZY9evtakEbVfOGLOS5ng7wGxGsYM3z5MqeUXPM/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP S01 - Light Curtain Check - Operator Side',
				link: 'https://docs.google.com/presentation/d/1A4GEO6aiR_sAndUYyFGjoDpe26YEw3sxxlffVIYPcLE/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP S02 - Light Curtain Check - Back Side',
				link: 'https://docs.google.com/presentation/d/1oHPFZDftW6TfjBcCieRt0WBQe7lM2GoybE9X-OPMatQ/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP S03 - Clutch-Stop CHeck on Main HMI',
				link: 'https://docs.google.com/presentation/d/1VxpoRmMWT_3Mg_Cj695KWXfOxzgOXRkx_EJUPtxck_E/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP S04 - Clutch-Stop CHeck on Palm Stand',
				link: 'https://docs.google.com/presentation/d/1KqKlFQpYMQLQUHER4rn5rS8wfbMzTSbTyxHMQJojwgU/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP S05 - Safety Block Check',
				link: 'https://docs.google.com/presentation/d/1mOJDMSjbhrwAgoN5l6hBRdJjEXBAe2mHfL1-Vj5D-58/edit#slide=id.g849f23443e_0_17',
			},

			{
				type: 'Calendar',
				description: 'AM Calendar',
				link: 'https://docs.google.com/spreadsheets/d/1TiZgQPjMvefB0Pvp0Pzn5_V2hNP-K1WDiognx2-hf6M/edit#gid=668593593',
			},
			{
				type: 'CILR',
				description: 'Route Map',
				link: 'https://interactivetrainingsystems.com/whirlpool/Tulsa/Eagle_Press/Eagle_300T_Press_AM_Route_Map_PRELIMINARY_3_11_22.pdf',
			},
			{
				type: 'CILR',
				description: 'CILR Tags',
				link: 'https://interactivetrainingsystems.com/whirlpool/Tulsa/Eagle_Press/CLIR_TAGS.pdf',
			},
		],
	},

	{
		name: 'Eagle Press 1100 Ton Press',
		documents: [
			{
				type: 'Ledger',
				description: 'Eagle Press Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1yt8U7xM0IvYyimR2kT7NmAZaug3WSzp2EbzRRA22ZWs/edit#gid=698683968',
			},
			{
				type: 'SMP',
				description: 'Standard Maintenance Procedure (SMP) Template',
				link: 'https://docs.google.com/presentation/d/12JIkZe-2d10QKbwR5_Ll5LxuMqK9lAcTsyl0vhQlCZ8/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP1 - Flywheel Cylindrical and Spherical Bearing Replacement (FINAL)',
				link: 'https://docs.google.com/presentation/d/1rrDqVr6OH6vJa9pVhkXXewP4O-s8oKMqheVukP9RLaE/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP2 - Orttech Clutch-Brake Replacement (FINAL)',
				link: 'https://docs.google.com/presentation/d/12xbLBtZgxHJx9a6S3XtGNW50_teMF6HVpdN_9lvu_gQ/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP3 - Main Motor Replacement (FINAL)',
				link: 'https://docs.google.com/presentation/d/1SCqZzqO_U6Of-eAc4U7mVrWuGBONqxVbS-nYCfiyJmc/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP4 - Flywheel Belt Replacement (FINAL)',
				link: 'https://docs.google.com/presentation/d/1Kz5ujwC2R0Jrb6B9Bzj8IDO_mHWkSOv1ZH3nsXS_o58/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP5 - Ram Leveling Procedure (FINAL)',
				link: 'https://docs.google.com/presentation/d/1VcoJ3jut_JXy37zBSjWU0_lKWR3OsWw8t4BtUuVvRB0/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP6 - Overload Valve Replacement (FINAL)',
				link: 'https://docs.google.com/presentation/d/1j3LBxA1cV2YtI1_1DG6tRexYnpP5FFvYXiDWBeXV5Kk/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP7 - Shut Height Adjustment Gearbox/Motor Replacement (FINAL)',
				link: 'https://docs.google.com/presentation/d/1X_i7VL_uMHe3yMs64geew0nrJomMkec_jTYy4qXtHeI/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP8 - Procedure if Press Gets Stuck on Bottom (Hydraulic Nut Operations) (FINAL)',
				link: 'https://docs.google.com/presentation/d/1dHafW9K9wRUW9aYyy2K5W7D9YU7hsBXZbE-H-gXqgr4/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP9 - Tonnage Monitor Set-up Procedure (FINAL)',
				link: 'https://docs.google.com/presentation/d/1L39yZ03CF3vKQS5MSxgMjaSeitEReajWzrzvRB2s11Y/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP10 - Pump/Motor Replacement - Hydraulic HPU (FINAL)',
				link: 'https://docs.google.com/presentation/d/1zVA3WNSIP2U7_ReKs8e-jN4ZR3h8K9KHB58Kcu-JQmM/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP11 - Ram Encoder Replacement / Set-up (READY FOR REVIEW)',
				link: 'https://docs.google.com/presentation/d/1t2xW5sm_UQzpHw-S4T4Anh0bNiuGqXzhR-_-fOgCAIs/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP12 - Lube Pump Replacement (FINAL)',
				link: 'https://docs.google.com/presentation/d/19-oRVK_GOZPNcWUwhYYSB1CT-71d4VQNoP0wRh6apuU/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP14 - JIT Pump and Valve Replacement (FINAL)',
				link: 'https://docs.google.com/presentation/d/1LwKw1fqZrqVm3iQRVcddSOkw0KE1nFYEULGrbUj-hCg/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP15 - Replacement and Adjustment of Press Gibs (FINAL)',
				link: 'https://docs.google.com/presentation/d/1SCRgzLC1SlzVql0t2H3zQAxQoDkH0k_ZsOxKIJvOrrw/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP16 - V-Belt Tensioning Procedure (FINAL)',
				link: 'https://docs.google.com/presentation/d/1oWfLt2_0PwZewCK3CUfJwfDnyPu7oblgVzRKXj4uuYU/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP19 - Priming the Lube System and Checking Motor Rotation (FINAL)',
				link: 'https://docs.google.com/presentation/d/1p7CEjrgNrOfNsjV-K2Z1jS5p3qdfIYrPupKRq3ArHxQ/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP20 - Lube Inspection of the Main Eccentric and Intermediate Gear Chambers (FINAL)',
				link: 'https://docs.google.com/presentation/d/1gTyerXcGtZOBksNw-27oK06gUwUNW_Hr9oeJ9a0xqpc/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP21 - Overload System Reset and Purge (FINAL)',
				link: 'https://docs.google.com/presentation/d/1qUQA7TtWk3MZabSPz010-Ma1uGLRaT1P1zHBNTDL1qk/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP22 - Dumping Air Out of Counterbalance Tank and Cylinder (FINAL)',
				link: 'https://docs.google.com/presentation/d/1n9U2nzJZDroVV2sxloUgmz5spEvEg16nkkpk7upfg7o/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP23 - Press Start-up and Shutdown Procedure (Waiting for Final Approval)',
				link: 'https://docs.google.com/presentation/d/1taV5mDZKOXk6Db9bxkTmt-adaCNmsr1ieYcsuw46by8/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP24 - Run Overload in Manual and Automatic (FINAL)',
				link: 'https://docs.google.com/presentation/d/1szQEtX88mLNfVlNeiro17okxB3vtzCCfVzUNQ90jC2k/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP25 - Bleeding Pressure OFF Overload System Cylinder (FINAL)',
				link: 'https://docs.google.com/presentation/d/1am_QT9vudtDItetR0Jb-uDDvujPqPBaqUzJZNSoN7-A/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP26 - Remove and Replace Bolster Lift Cylinders (FINAL)',
				link: 'https://docs.google.com/presentation/d/1Y7Fp1-7bDdxXMlxJcgfbKQs3naAKgJSWjiSGC-_mlD8/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP27 - Remove and Replace the Bolster Clamp Cylinders (FINAL)',
				link: 'https://docs.google.com/presentation/d/18jZ9ISB5y-95KI2NnmlDTgyarnH0swZBb0_2z5ClZzo/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP28 - Die and Bolster Changeout (FINAL)',
				link: 'https://docs.google.com/presentation/d/16t2gwbMqUAk_qNTCFc3VQ9L8Tw4sgiEz_kNjnXZ4RW8/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP29 - Stroke Timing Assembly Encoder Replacement (FINAL)',
				link: 'https://docs.google.com/presentation/d/1hCw4OdhyNzxb6nng6M56yk8tE4dVgh-ZGhLGx-u5lTY/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP30 - Motor Mount Assembly Pillow Block Cleaning and Regreasing (FINAL)',
				link: 'https://docs.google.com/presentation/d/19wtFVjK3fUxhiW2cfP7zvIP7aaK5jKswCO4iDTQDjPU/edit#slide=id.p1',
			},
			{
				type: 'SOP',
				description: 'S01 Area Scanner Check - Press Front',
				link: 'https://docs.google.com/presentation/d/1tPyVwXDICArUFMIJjSZt3lEX3skiRMosmjZMihu9uCY/edit#slide=id.p1',
			},
			{
				type: 'SOP',
				description: 'S02 Area Scanner Check - Press Rear',
				link: 'https://docs.google.com/presentation/d/1Dny4zY5VdWRqAYZoS3pYlbD37_U6iFajXMUM45purG8/edit#slide=id.p1',
			},
			{
				type: 'SOP',
				description: 'S03 Clutch-Stop Check on Main HMI',
				link: 'https://docs.google.com/presentation/d/19I_NLAzOXZ-p5xwerx5rrixqMb2bzw3FROyQN5hSKVk/edit#slide=id.p1',
			},
			{
				type: 'SOP',
				description: 'S04 Clutch-Stop Check on Palm Stand',
				link: 'https://docs.google.com/presentation/d/1hASqB2YNP3m18gFouITQ1cr-z9a6GLQ_k-0iK2xhgNA/edit#slide=id.p1',
			},
			{
				type: 'SOP',
				description: 'S05 Front Left Safety Block Check',
				link: 'https://docs.google.com/presentation/d/1ADrDwFxynYOWLzjMsv4nFDJNWkwgA2r7yxfk0uAnpzM/edit#slide=id.p1',
			},
			{
				type: 'SOP',
				description: 'S06 Rear Right Safety Block Check',
				link: 'https://docs.google.com/presentation/d/1wwZYVREMz6OxP36ro5cQqNH8VDBmCnnda53oB7aK6N8/edit#slide=id.p1',
			},
			{
				type: 'SOP',
				description: 'S07 Clutch-Stop Check on Junction Boxes',
				link: 'https://docs.google.com/presentation/d/1NnPN3hq70JoBuMgtYcdhrKECVldfr4_Or1V4uybUlTo/edit#slide=id.p1',
			},
			{
				type: 'SOP',
				description: 'C01 Clean Orttech Panel on Top of Press (Mezzanine)',
				link: 'https://docs.google.com/presentation/d/18y3zO-xxbIHAb9RV0ijzWuO5wujz-6bfR4_r5QNPxO8/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'C02 Clean Bolster and Lower Clamps',
				link: 'https://docs.google.com/presentation/d/1p_H3Dg3T3wyqFiLvRSSqocqIIrupgEeClYO4PdxNUso/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'C03 Clean Overload Tank and Valve in Slide',
				link: 'https://docs.google.com/presentation/d/1b-1aVWeuqB6LXrJ7UR2vBwMJjCOkf_rpicOwygy6R0c/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'C04 General Press Cleaning',
				link: 'https://docs.google.com/presentation/d/1-FkjV93N59YKSo62vaNnszKs7EB2bnPjXg274g8LyLg/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'C05 Clean Orttech Hydraulic Power Unit',
				link: 'https://docs.google.com/presentation/d/1VHUIectig-2ARZqey3hRplBNpmw_P4BVUUbSjsGdK9o/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'C06 Clean HMI Panel and Pedestal',
				link: 'https://docs.google.com/presentation/d/1AblXz4soOsoMBOwFsCDXkQ3gml-LcAI6eEJhUBgQRGY/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'C07 Clean Main Electrical Panel',
				link: 'https://docs.google.com/presentation/d/1O4rApEbKiASv8oRu1zd5S2Nmi_HxXqErCFlp2TVlQD8/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'C08 Clean Mechanical Control Panel',
				link: 'https://docs.google.com/presentation/d/1dY3or8ID4q9SjpZzJ4N7VNWR9RQ0MIDCjsfSIpPSRLM/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I01 Inspect Orttech Control Panel On Top Of Press',
				link: 'https://docs.google.com/presentation/d/1zHZt9bSnAai-ODjUqyQoYxFJOnAhxyoQqRjmIM9PqkM/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I02 Inspect Inside of Mechanical Cabinet (Floor)',
				link: 'https://docs.google.com/presentation/d/1oCoWmZ94K4l2PY_03AqKESKVsOU2f0IC9fa_Ec2ZqDw/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I03 Inspect Gibs On All Four Corners',
				link: 'https://docs.google.com/presentation/d/1DU3pr8KIsnC1sgeIt16jih7dYndERynrad21A_Himc4/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I04 Inspect Overload Tank and Valve in Slide',
				link: 'https://docs.google.com/presentation/d/1AD4khRBL59a385z8vhswWPQ2hPwKwFpOhM2FZyI7jd0/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I05 Inspect Flywheel Brake Cover (Mezzanine)',
				link: 'https://docs.google.com/presentation/d/1O-P6JxHIUz2UfHTA_IGl8AMV13-ObwvEQgGVqtV1H_8/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I06 Inspect Main Lube System Tank (Basement)',
				link: 'https://docs.google.com/presentation/d/11if2CDwjDbkP1tndL4oENV0T73QM3r3WNq2xKBllYmU/edit#slide=id.p1',
			},
			{
				type: 'SOP',
				description: 'I07 Inspect Match Marks on Press',
				link: 'https://docs.google.com/presentation/d/186hI9vPTs5RuiepYH_x2CRqrYk9gvO7o4ynlXnKJv2M/edit#slide=id.g117897807de_0_0',
			},
			{
				type: 'SOP',
				description: 'I08 Inspect Orttech Hydraulic Power Unit',
				link: 'https://docs.google.com/presentation/d/1_xX8w7T9qtK3RMjKhr1MXt_9QDvaoIcOrwJf7NtRwlw/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L01 Front LH Counterbalance Cylinder Rod Seal Lubrication',
				link: 'https://docs.google.com/presentation/d/19Yn5wPhy7HhepFSqpVYYBr9oJoxWmY3qyE2TuCyEHoA/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L02 Front RH Counterbalance Cylinder Rod Seal Lubrication',
				link: 'https://docs.google.com/presentation/d/1vmcT5df-17qe3XNw_wOFw6zdZkknel0Uhi6RvTHeT-U/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L03 Main Motor Lubrication',
				link: 'https://docs.google.com/presentation/d/1sOBXLUNxiYPIekEfwhvqBvxAQsSRoAmWkwtq7vQZk1A/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L04 Lube Pump Motor Lubrication',
				link: 'https://docs.google.com/presentation/d/1Lsezfu0g_wXNClJ4VlKDwV_wCQVJlRGTrIRFWP5xTJY/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L05 Lift and Clamp Pump Motor Lubrication',
				link: 'https://docs.google.com/presentation/d/1rBJ4E3Ok3on7zArpjb7OnDX_wSUhsKmEuk58kkYaXEg/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L06 Checking the Oil Level to the Bolster Drive Motor Gearbox',
				link: 'https://docs.google.com/presentation/d/1-k_l6HlaOriWrtpvlqJF9QUsclrjOEEF7Yj0ilvHdBU/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L07 Changing the Oil to the Bolster Drive Motor Gearbox',
				link: 'https://docs.google.com/presentation/d/1fsOHcdbtwa01D-LjgS3-7Qe9qpqvbzYLDYlsQ9WPe8w/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L08 Motor Mount Assembly Pillow Block Greasing',
				link: 'https://docs.google.com/presentation/d/19msARHFXsgbL3jmbHJOEvI9mrjTiB382At1H9z_lEC8/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'Calendar',
				description: 'AM Calendar',
				link: 'https://docs.google.com/spreadsheets/d/19-DauaC5fk5EfCQ9tFxzsaEgErIWG8DQ9wDJEv2lYMM/edit#gid=668593593',
			},
			{
				type: 'CILR',
				description: 'Route Map',
				link: 'https://interactivetrainingsystems.com/whirlpool/Tulsa/Eagle_Press_1100/Eagle_1100_Ton_Press_with_Linear_Colt_AM_Route_Map_final.dwg',
			},
			{
				type: 'CILR',
				description: 'CILR Tags',
				link: 'https://interactivetrainingsystems.com/whirlpool/Tulsa/Eagle_Press_1100/CLIR_TAGS.pdf',
			},
		],
	},

	{
		name: 'Colt Automation Feeder Equipment',
		documents: [
			{
				type: 'Ledger',
				description: 'Colt Feeder Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1DlrMEf3PgaoTOfiuiB52W9GRV8y8TNzs7dA6q7n_qis/edit#gid=926335867',
			},
			{
				type: 'SMP',
				description: 'SMP Start Sheet',
				link: 'https://docs.google.com/presentation/d/12JIkZe-2d10QKbwR5_Ll5LxuMqK9lAcTsyl0vhQlCZ8/edit?ts=60d07f7e#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP 001 - Parker Hydraulic Motor Replacement',
				link: 'https://docs.google.com/presentation/d/1OaNg0fJeGUYA1kjC8j53Yu-sBQxemt-mK3aqJxrEd10/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP 002 - TSUBAKI DRIVE SPROCKET REPLACEMENT',
				link: 'https://docs.google.com/presentation/d/1uM-mhzbypVHbLQPzhopXgQEzgyRbRU0pgY2duvs0YDU/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP 003 - TSUBAKI CHAIN SPROCKET LUBRICATION',
				link: 'https://docs.google.com/presentation/d/1cY8pOFaWII5Tp-UJT4V9-vMFT5ps7dhICXngNV4w2SI/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP 004 - TSUBAKI DRIVE SPROCKET REPLACEMENT',
				link: 'https://docs.google.com/presentation/d/19nxshq7r-q_8dDjoyWZ6DOfEs6N671zu5oEv_2FBgGs/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP 005 - MANDRELL SPROCKET REPLACEMENT',
				link: 'https://docs.google.com/presentation/d/1zyVwT8AxcwLNXUSMAnHZ4dBf0t9akxPIuA-avO0h2os/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP 006 - PARKER HYDRAULIC CYLINDER REPLACEMENT',
				link: 'https://docs.google.com/presentation/d/13AFltuN51u9N8bK9jPPMUMYtByTNCRHqS0STdYdYLW4/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP 007 - POWERSTROKE UTILITY CYLINDER REPLACEMENT',
				link: 'https://docs.google.com/presentation/d/1uFHSS6m4FSVHqZumOZeBGcOYlRMuad_udZMos6Zeaj0/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP 008 - PARKER CYLINDER REPLACEMENT',
				link: 'https://docs.google.com/presentation/d/1U7XLQulZVYPop-RlpQYAe706NkI0axYF396SttsnxVs/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP 009 - ROLLER REPLACEMENT',
				link: 'https://docs.google.com/presentation/d/1hhLyECCJ_Bo2cFJoPRXdpZq7OjWBFnNRlV0Kd6ROiXM/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP 10 - BAUER MOTOR REPLACEMENT',
				link: 'https://docs.google.com/presentation/d/15uoFVUPgfOBHWeKUYh3yasUiMUl_UwfSqn09NkCKKjw/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP 11- MANDRELL CHAIN REPLACEMENT',
				link: 'https://docs.google.com/presentation/d/1XGssypvoTBJk7MIL6cyIACBRpoBbq676TUMmAon5tlY/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP 12 - MANDRELL ROTARY UNION REPLACEMENT',
				link: 'https://docs.google.com/presentation/d/1783pib_y386QqonwiefWMOQZg-gp7Lk-2daicGctrws/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP 013 - PEELER CHAIN REPLACEMENT',
				link: 'https://docs.google.com/presentation/d/1hMI85PovKHzt148FjD315GbsyJvOv0caIJ2SXObAunw/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP 014 - PEELER SPROCKET REPLACEMENT',
				link: 'https://docs.google.com/presentation/d/1OSyJ7wMkJtWGaasMc635mJgNUj3KW5lcZAIdfK54gtA/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP 015 - ROLLER SPROCKET REPLACEMENT',
				link: 'https://docs.google.com/presentation/d/1GkbDcHdy4auiWDLb9q56DvuPXz2nogQZNw85CQ7A31s/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP 016 - PEELER CHUTE CYLINDER REPLACEMENT',
				link: 'https://docs.google.com/presentation/d/1J3biUjkTA9YmeX3NinZzVlQ-s6QtU-QOdhIpY4q3jBQ/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP 017 - PEELER BLADE CYLINDER REPLACEMENT',
				link: 'https://docs.google.com/presentation/d/1qox4QCFCQwIrfyc0LdW-koCSAuQiwUWXwt7pSPxgcxw/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP-18 - Straightener Alligator Pinch Roller Cylinder Replacement',
				link: 'https://docs.google.com/presentation/d/19Hr3q1Sz4s-XkaEwsySw0e2bDMd-Md3MDvQBsvix-2w/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP-19 - Straightener Alligator Latching Cylinder Replacement',
				link: 'https://docs.google.com/presentation/d/1iX55hyFylDJ_p6o-INJG5tjKuT3B51YJ0XD39mOTHVU/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP-20 - Straightener Kicker Lock Cylinder Replacement',
				link: 'https://docs.google.com/presentation/d/1WXl46aKc3lAhC-HskZRUZZeiF0byvaCmmJbrBRue7DY/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP-21 – Straightener Jack Replacement - Operator (Gear) Side',
				link: 'https://docs.google.com/presentation/d/1ZU7f3FvNH_9MX3l9-ozuAuDjJRD8UIzlV8vwk6nCZVI/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP-22 – Straightener Alligator Open/Close Cylinder Replacement',
				link: 'https://docs.google.com/presentation/d/1pNBdx41zi6cCKFaM8d-gL3eMvboZogq9dxriMTGDCgw/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP-23 – Straightener Jack Replacement - Opposite Operator (Sprocket) Side',
				link: 'https://docs.google.com/presentation/d/1rHqo0sxu-ftPKR1hzM-nypNhwpSOFu4yTWdcKd4nhdw/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP-24 - Straightener Top Straightener Roll Replacement',
				link: 'https://docs.google.com/presentation/d/1-fQdf0ps4fBInkDB0dBq1R643CGlArGuQUiXDMc98x0/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP-25 - Straightener Top Pinch Roll Replacement',
				link: 'https://docs.google.com/presentation/d/1XnGVCZx1ZCdfY4SIqoOB3cdRm4V0D98jJydxH7MVajA/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP-26 - Straightener Bottom Straightener and Bottom Pinch Roll Replacement',
				link: 'https://docs.google.com/presentation/d/11W2a8BR5O7rlo0JX5TUWudO60gwg8_dZPFhuycwsFac/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP-27 - Feeder Anti Back-Up Top Rollers Replacement',
				link: 'https://docs.google.com/presentation/d/1BlWzFAz8_X7XLguYHVwM7dklWOJ3fpxhFI2f-iduyPM/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP-28 - Feeder Anti Back-Up Bottom Rollers Replacement',
				link: 'https://docs.google.com/presentation/d/1Whg0Wq6dKps6xcaerRdSN1YqUkNR6YTjNIzCSXS2WKA/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP-29 - Feeder Top Feed Roll Replacement',
				link: 'https://docs.google.com/presentation/d/1zBlKVKddiaa-S74jDn_cQbtqF2nI7ezUTdYdsZMLASE/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP-30 - Feeder Bottom Feed Roll Replacement',
				link: 'https://docs.google.com/presentation/d/1AqOujv_xnVjE-Fz7FmHLc7KlNfSEFd3IdViwIHkA1Ro/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP-31 - Feeder Feed Rolls Cylinder Replacement',
				link: 'https://docs.google.com/presentation/d/1IIaRffDb92VCcy9Pf9T5aMkR2N5HBWwB6ThChUuk1UE/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP-32 - Feeder Anti Back-Up Rolls Cylinder Replacement',
				link: 'https://docs.google.com/presentation/d/1EcXsi_VGTg9PkoNTnt6hM3Eb7omHizUlVjchvK-Ws4w/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP-33 - Straightener Stock Guide Motor Replacement',
				link: 'https://docs.google.com/presentation/d/1SNb-X3xlxxjePGXx26n2jQnrLqjQCSZaKoY0f6dOB0A/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP-34 - Feeder Stock Guide Orbit Motor Replacement',
				link: 'https://docs.google.com/presentation/d/1_xVS3Dv23ofOCwbOQHIFhDtuI2ozGDxwB9hMIaZe2gQ/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP-35 - Straightener Pivot Table Cylinder Replacement',
				link: 'https://docs.google.com/presentation/d/1QsTT6wB4xThC0_44yYxLNAn0wLde1_SOm0eAnhk9OKU/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP-36 - Feeder Jack Replacement',
				link: 'https://docs.google.com/presentation/d/1_EWbDS_6pKh6ZIExT8XjwZJgbjLtUZjsqMuVEUOALbM/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP-37 - Feeder Orbit Motor Replacement',
				link: 'https://docs.google.com/presentation/d/1YSUTOzGShqa7s2RlUW8eRDrge3CcNXOuJOwqIRGCr8w/edit#slide=id.p1',
			},
			{
				type: 'SMP',
				description: 'SMP-38 - Feeder Motor Replacement',
				link: 'https://docs.google.com/presentation/d/1WC59iuqUkHKIJB-1hRzXtm9yfob9CZB0eXRhNMgs-x8/edit#slide=id.p1',
			},
			{
				type: 'SOP',
				description: 'SOP Start Sheet',
				link: 'https://docs.google.com/presentation/d/1ho6D3zQ7Sgm68vLQ3jJVYgWMuFON_M5xY0PboJSFaRM/edit?ts=60d07f95#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'C16 General Cleaning of Traveling Uncoiler Assembly',
				link: 'https://docs.google.com/presentation/d/1T2kr4oAeaNisIUfAThB6Q-wjAH9M5_61HrFCvP4piAo/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'C17 Cleaning the Uncoiler Bauer 5HP Motor',
				link: 'https://docs.google.com/presentation/d/1y2wjJJZxsOfvLuVXPG2T158V33hKF4xLUSe_cFcR2bI/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'C18 Cleaning the Peeler',
				link: 'https://docs.google.com/presentation/d/1QZjloXsnb5my090AA6RXlWWkMHQR4RUAOTqIO4BCi9k/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'C19 Cleaning the Graco G3 Max Pump',
				link: 'https://docs.google.com/presentation/d/1Od_-MuOveEef0WAPfsdm9nz5-rZZRBILL8jVsGqe0io/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'C20 General Cleaning the Feeder',
				link: 'https://docs.google.com/presentation/d/1YRpeEund8bQFvfi2ex6-3JZ2QmEReEs3J4zGHxFbEkc/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'C21 Cleaning the Straightener',
				link: 'https://docs.google.com/presentation/d/10IFAvVe_ysMh-WbQMaZGL-iAIPqH9HcJhpjSNxoJ3eA/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'C22 Cleaning the Coil Car',
				link: 'https://docs.google.com/presentation/d/1e0xyWEehnQ6ntcESHMg4Zk-L7FcS8NWyJBnrezBZDWQ/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'C23 Cleaning the Uncoiler OD Sensor',
				link: 'https://docs.google.com/presentation/d/1KX4xEFvh85BUkjIqMJLuj5CfHSZ5F94yczakGoS9iI8/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I17 General Inspection of Traveling Uncoiler Assembly',
				link: 'https://docs.google.com/presentation/d/1EdCsGP0kgL1hvoSrP4JB8h68RFXbm_7U71MotTUztao/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I18 Inspection of the Uncoiler Bauer 5HP Motor',
				link: 'https://docs.google.com/presentation/d/1_adxwnovb1ArwU3IjtOzmJHLSwHDEiYIGCYSH4a-0jo/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I19 Inspection of the Uncoiler Mandrel',
				link: 'https://docs.google.com/presentation/d/1rUq8cVoMBqfw1Ykhl_Mj6gBVx5MCs4hp4oDVPIhs5D4/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I20 General Inspection of the Coil Car',
				link: 'https://docs.google.com/presentation/d/1vdo73OnnvdekJLx95smou-K6VVuKdEfHOGwXX8fm5fg/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I21 General Inspection of the Peeler',
				link: 'https://docs.google.com/presentation/d/1z_a9dF1_q0ILuEZMY8S8j_vF_hU7WJ-e5Eb-KSf2Y2E/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I22 Inspection of the Graco G3 Max Pump',
				link: 'https://docs.google.com/presentation/d/11BuJGHShGrQypGmEonPaAYYqiiKH876WJO9TMCFHEiI/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I23 General Inspection of the Feeder',
				link: 'https://docs.google.com/presentation/d/14L3zl0iZmXRIOXie-5WpiOpNjBEaUB-AQ1MviKRE1b8/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I24 Inspection of the Straightener Parker Hydraulic Motor',
				link: 'https://docs.google.com/presentation/d/1IBK5vlD9kwAMTFXWmsfCegl5ERncbTi7d3rK8mkeZaM/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I25 General Inspection of the Straightener',
				link: 'https://docs.google.com/presentation/d/1U9-J_0bxCNAAlG2pkz776KI3GkuAHJ7te97HDY1lTGk/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I26 Inspection of Feeder Stock Guide Offset',
				link: 'https://docs.google.com/presentation/d/1I44TQNDavY_EXyOJisOf3kjy1q88aT4EMKW6KSgk1Go/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I27 Inspection of Uncoiler Measurement Indicator',
				link: 'https://docs.google.com/presentation/d/1ZXv4PyzMtw0cWZmSw2Wxn_30JtXhTjXqEK8y6njsaYQ/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I28 Inspection of Straightener Linear Encoder',
				link: 'https://docs.google.com/presentation/d/1v9qL4ctReGOH3CwsyB43yAbbMIIUscKeBZN7VbecFmM/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L18 Lubricating the Uncoiler Tsubaki Chain',
				link: 'https://docs.google.com/presentation/d/17_GgmI3jTNPvviutFj3V0WRSjj1BtMvGoukVhX3FsJU/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L19 Lubricating the Uncoiler Spider Shaft',
				link: 'https://docs.google.com/presentation/d/1JOusmnnE4AZ7AiwOsWHjQn4O_RtrWL40Ipr8IWHnU44/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L20 Lubricating the Uncoiler Wheel & Shaft Bearings',
				link: 'https://docs.google.com/presentation/d/1-UIrkuMU_uFZ6WTEftIqiK4QhFmOk7msM3fug61d3Qs/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L21 Lubricating the Peeler/Threader Pivot Points',
				link: 'https://docs.google.com/presentation/d/1WrjknLmB-XiUaTAdoOsS3iZ_zHLbGF2Qc8MSqI8w3TY/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L22 Lubricating the Peeler Hold Down Arm Roller',
				link: 'https://docs.google.com/presentation/d/1LWkyiJy3QNBAWfXjPXKCJkU1W--4I3pzeLDCBowUWzI/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L23 Lubricating the Straightener Stock Guides',
				link: 'https://docs.google.com/presentation/d/1SvGjeMAmE0ORi7FiUYx-BQAz99bN7iKHMJX6DBHI2U8/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L24 Lubricating the Roll Feeder Stock Guides',
				link: 'https://docs.google.com/presentation/d/1NPh7pTTHORrHkly0-48dVH9FQb7aNYn5KYKdT4GTyu8/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L25 Lubricating the Uncoiler Mandrel Bearings',
				link: 'https://docs.google.com/presentation/d/1Yx3AnGcycuG7KIuC2HC_9mbr_HVHdioZlBgyJrw0ZPQ/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L26 Lubricating the Peeler Threader Chain',
				link: 'https://docs.google.com/presentation/d/1bGIXkPmva20o5Nu0AqC7zxzlicxQ9IT2vHobc5Yrhfw/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L27 Lubricating the Straightener Take Up Blocks',
				link: 'https://docs.google.com/presentation/d/1ucQF7JMIy0TYn1rPmrqzRBcQ3lb9Nk4ms3rHPVsu24M/edit#slide=id.g15066719b28_0_6',
			},
			{
				type: 'SOP',
				description: 'L28 Lubricating the Straightener Jacks',
				link: 'https://docs.google.com/presentation/d/1GxIH21K6LPBxDXjneC3qXJPLQNHJUJXjGRiVRVaURNk/edit#slide=id.g15066719b28_0_6',
			},
			{
				type: 'SOP',
				description: 'L29 Lubricating the Feeder Jacks',
				link: 'https://docs.google.com/presentation/d/1YZl383saYdsILIbqUl_0xRzj9Q5uQVTvWytztlzy3Io/edit#slide=id.g15066719b28_0_6',
			},
			{
				type: 'SOP',
				description: 'L30 Lubricating the Feeder Roller Bearing',
				link: 'https://docs.google.com/presentation/d/1h9K56mHauCGCENO7NfM3yqULNG3SnoaM0hR_TTe3A10/edit#slide=id.g15066719b28_0_6',
			},
			{
				type: 'SOP',
				description: 'L31 Lubricating the Feeder Sliding Gibs',
				link: 'https://docs.google.com/presentation/d/1kvHoFunR5rVUpuGMmQx5f8aWbRTWzTbq9lERPFm8RtY/edit#slide=id.g15066719b28_0_6',
			},
			{
				type: 'SOP',
				description: 'L32 Refill Peeler Grease Reservoir',
				link: 'https://docs.google.com/presentation/d/1USYDGMMSHWD6SAN3hT_pdL2o_cCYLM7SCKgqdjEQZhI/edit#slide=id.g15066719b28_0_6',
			},
			{
				type: 'SOP',
				description: 'L33 Lubricating the Uncoiler Leafs',
				link: 'https://docs.google.com/presentation/d/1D6TQyVmVm_LDkldBTw1eGi-FnZ3Ber5IwzePwoC0Muo/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L34 Lubrication of Feeder Central Manual Lubrication Block',
				link: 'https://docs.google.com/presentation/d/1IDOxtdX2SLmFE_nM7J6U-YmxNK1qr09vAfseVu4_XLM/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'S13 Safety Inspection of the Sick Safety Scanner',
				link: 'https://docs.google.com/presentation/d/1fERhD3X9TUbCoxi4shgQoVr6FfEziTrk4boIBk5PLqI/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'S14 Safety Check Straightener Emergency Stop',
				link: 'https://docs.google.com/presentation/d/14yNqlFdo3JNltXJiEnVjRvb3O8z_1MIgOx8T47hd6qo/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'S15 Safety Check Feeder Emergency Stop',
				link: 'https://docs.google.com/presentation/d/1yRzi8EQymtVGKYCc0COyXx2jWaldkDB1xz3OPk2CMa4/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'Calendar',
				description: 'AM Calendar',
				link: 'https://docs.google.com/spreadsheets/d/1d1WBJI9vQN-VvMqETaN7t0KlXGPOjyYZObWx3iE8LtQ/edit#gid=1685654886',
			},
			{
				type: 'CILR',
				description: 'Route Map',
				link: 'https://interactivetrainingsystems.com/whirlpool/Tulsa/Colt/Eagle_1100_Ton_Press_with_Linear_Colt_AM_Route_Map_final.dwg',
			},
		],
	},

	{
		name: 'Linear Transfer Equipment',
		documents: [
			{
				type: 'Ledger',
				description: 'Linear Transfer Equipment Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1Tt0AMcMXr26i4uJxiIJsal7q5_togsDHP9CLtPS3hlM/edit#gid=926335867',
			},
			{
				type: 'SMP',
				description: 'SMP Start Sheet',
				link: 'https://docs.google.com/presentation/d/12JIkZe-2d10QKbwR5_Ll5LxuMqK9lAcTsyl0vhQlCZ8/edit?ts=60d07f7e#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP01 - SEW GEAR MOTOR REPLACEMENT PROCEDURE',
				link: 'https://docs.google.com/presentation/d/1ixYi1Bm5oQlb-yn15C8eN7gmsjU2If2NqnJjGFHJ-EA/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP02 - SEW GEAR MOTOR OIL CHANGE PROCEDURE',
				link: 'https://docs.google.com/presentation/d/1Vhs9d06EXAqicsyL53Lp6debv9IL_Ne5z3eWOwNf6h4/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP03 - X-AXIS BELT TENSIONING PROCEDURE',
				link: 'https://docs.google.com/presentation/d/1vpQCHtgU1gionZpQaX04qMb1-L3E4m487wROAy8nFtE/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP05 - ALLEN BRADLEY MPL-B960D-MJ74AA SERVO MOTOR REPLACEMENT',
				link: 'https://docs.google.com/presentation/d/1hRUbeJX_Q88-j5AWV2Z7BLZcX3rTotSh_81Omo26l1w/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP06 - REPLACEMENT OF ALPHA GEARBOX',
				link: 'https://docs.google.com/presentation/d/1DGeV_ve_ZjsQ9SbJQpmv8WlSX9Ig9-uUVsgYbwyeCH8/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP07 - MYAR BRAKE REPLACEMENT',
				link: 'https://docs.google.com/presentation/d/170PigL2ZoybcJBEW4PNSgi3OZVMz9NZ71Z_WYpQojLk/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP08 - JACKSCREW REPLACEMENT PROCEDURE',
				link: 'https://docs.google.com/presentation/d/1LwGL20lqbvzYecKhLLh06BUDi2MW9VGkf2JWL4qb4iI/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP09 - BALL SCREW REPLACEMENT',
				link: 'https://docs.google.com/presentation/d/1-kPyOi-fOfgl3IZzb-V5Ztam1ukr4jtvBpPDPcRZTSI/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP10 - X BEAM ASSEMBLY LINEAR RAIL / BEARING REPLACEMENT',
				link: 'https://docs.google.com/presentation/d/1JUbnVzMyZCwJMMO5ae3OZJ6jeWL3LmPxsGXhBjrAd14/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP11 - Y-SLIDE LINEAR RAIL / BEARING REPLACEMENT',
				link: 'https://docs.google.com/presentation/d/1NrxjDzsbByGVz56tsina20XvwtFGcPad69lFLNTZhZ0/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP13 - PARKING POSTS LINEAR RAIL / BEARING REPLACEMENT',
				link: 'https://docs.google.com/presentation/d/1N-o1fE0YCHqwe4nGaALVRGVqkfpb09BJROVfhnZCzSc/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP14 - Y-AXIS BELT TENSIONING PROCEDURE',
				link: 'https://docs.google.com/presentation/d/1oHhXmQR0ikOFWlW18U7RB6WM6brQqm47-x8vhf38wB8/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP15 - X-AXIS BELT REPLACEMENT PROCEDURE',
				link: 'https://docs.google.com/presentation/d/1MjR4i_OUPHASCFRoAQauqBLIYTPQwvf_V9nEXFkoy6A/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SMP',
				description: 'SMP16 - Y-AXIS BELT REPLACEMENT PROCEDURE',
				link: 'https://docs.google.com/presentation/d/1SvDgTtusTgqJsEh-t4Uh58MhhoQEMT9rpNJg7dLy5HU/edit#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SOP',
				description: 'SOP Start Sheet',
				link: 'https://docs.google.com/presentation/d/1ho6D3zQ7Sgm68vLQ3jJVYgWMuFON_M5xY0PboJSFaRM/edit?ts=60d07f95#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'C09 General Cleaning of Transfer and Press',
				link: 'https://docs.google.com/presentation/d/1xJXNBRglSVBi-EoJg7_OVo3VWcgwDZqk5wuz6THLoec/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'C10 Cleaning of LH Motor Assembly',
				link: 'https://docs.google.com/presentation/d/1PVIyO8svatDh6W9RGX3COPWeXhNJUoxNkTXL5VBM1s8/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'C11 Cleaning of Allen Bradley MPL-B960D-MJ74AA Gear Motor',
				link: 'https://docs.google.com/presentation/d/16SfPovCDVwNBDdNXEIZUhSR-aEYiv2if7b8clSewAkA/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'C12 Cleaning of Transmission Assembly',
				link: 'https://docs.google.com/presentation/d/1dbsyFkKXVAJlla_djevMDRJEAtlMmXOET2bgvThNWic/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'C13 Cleaning of SEW Gear Motor',
				link: 'https://docs.google.com/presentation/d/1Qm7yHUilHkaZMyKUYZoFRrPKgqBDhkTH_WU5UgXCtVM/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'C14 Cleaning of Transfer Bar and Tooling',
				link: 'https://docs.google.com/presentation/d/1COqzMxNjp-gOMT7VlO_409zNOvcSkrMZRJ9F_oeWX54/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'C15 Cleaning of Z-Box Assembly',
				link: 'https://docs.google.com/presentation/d/1Jbf6BD7O9Uo6T6HIxOB_O3V6CPJd6kX6y9Cr7EbF3Jw/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I09 General Inspection of Transfer and Press',
				link: 'https://docs.google.com/presentation/d/1B9pJbW_ah8fb7P3LeFyVg8pv42RFKjqQy70uw7WfNCY/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I10 Inspection of LH Motor Assembly',
				link: 'https://docs.google.com/presentation/d/1R8oBCR0_oUjyYc8cXtkqWEbvRlpPpQCF3YxlSciNwrw/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I11 Inspection of Allen Bradley MPL-B960D-MJ74AA Gear Motor',
				link: 'https://docs.google.com/presentation/d/1SfLTEaFxcOV5BvWpXt9OQh8LWwpfUr_4GhBbRR4LCGA/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I12 Inspection of Transmission Assembly',
				link: 'https://docs.google.com/presentation/d/1_boWLPL01sSw4nC5ZNEPin5_MRMxiknpjuQ_nUW3oLg/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I13 Inspection of SEW Gear Motor',
				link: 'https://docs.google.com/presentation/d/1OCDarXxEQm6bXIuIH-PL9zWIpgwzod_uZe0vFOsXtso/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I14 Inspection of Transfer Bar and Tooling',
				link: 'https://docs.google.com/presentation/d/1Sh4UFhWl0VKEmIije4lU7aFGv9XoJBAKOJIcg5QFlpo/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I15 Inspection of Z-Box Assembly',
				link: 'https://docs.google.com/presentation/d/1l1xZGiL9o1HyEklsypy5o5NfjdryDSib_l66zJ8kLuw/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'I16 Inspection of Graco G3 Lubrication System',
				link: 'https://docs.google.com/presentation/d/1azxIfx0Ao2tuGfJHx_DoYNIYhsWqBOQ1WdSoeesE00s/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L09 Filling Graco G3 Lubrication Pump',
				link: 'https://docs.google.com/presentation/d/14HgWLncWdwMDLz7HMCjzUtJ0453yScy8sWm2qwscXjY/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L10 Filling Graco G3 Lubrication Pump',
				link: 'https://docs.google.com/presentation/d/1U636cJMEJHbviIj-JCkpVm5PhUMdlKceJa6IaMs7wU0/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L11 Lubrication of Joyce Jack Screw',
				link: 'https://docs.google.com/presentation/d/1QD1RbFNBds_6Sv1jYZzG7s8h9T3FKQgo7eufqwpbF00/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L12 Lubrication of Front Right Pillow Blocks MA00936-1',
				link: 'https://docs.google.com/presentation/d/1akpZGbpOJCkrF-uFApE21wfqTpvBPV9zdZXRYk9zdqk/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L13 Lubrication of Front Middle Pillow Blocks MA00936-1',
				link: 'https://docs.google.com/presentation/d/1IjMynOOdFaN8mz7bDOxjxPIEfvi6BVt6N1GclP4FlcY/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L14 Lubrication of Front Left Pillow Blocks MA00936-1',
				link: 'https://docs.google.com/presentation/d/1tPhMabD-uJqDDRsC_K1hK-h_l01OzaZ9kBSrRDs5np0/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L15 Lubrication of Rear Right Pillow Blocks MA00936-1',
				link: 'https://docs.google.com/presentation/d/1BkIG70WyXJPKtXVV_HGch2_Exv4wQuEbOrQpqWJxBN0/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L16 Lubrication of Rear Middle Pillow Blocks MA00936-1',
				link: 'https://docs.google.com/presentation/d/1A5VU5pvE5rAswH7GBGbO4FTpmFo6rCNomEuRy2QBhPA/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'L17 Lubrication of Rear Left Pillow Blocks MA00936-1',
				link: 'https://docs.google.com/presentation/d/1-EU5_RaNOcQRgVmIC437jCRhd4pNRcRwDnJBrh7mpRU/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'S08 Safety Inspection of SICK Safety Laser Scanner',
				link: 'https://docs.google.com/presentation/d/1g4SdpA_7528xiSEKIrh9pQbmm4Lg8Bku2AjKF_lwI1A/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'S09 Safety Check of Fortress Safety Gate Switch',
				link: 'https://docs.google.com/presentation/d/1clW8675-6QjZYhH1ERxVfXZL28rH47Bb7p83SstILEE/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'S10 Safety Check of Emergency Stop',
				link: 'https://docs.google.com/presentation/d/12hAnkQ8IZ_dpb7zhKRlooE4tFurUZKPSqLTT4J677z4/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'S11 Start Up Procedure',
				link: 'https://docs.google.com/presentation/d/1sGq73lm5SU-KOKLvjzN2XAHqXJDdN0w5d3fdGqvm5A0/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'S12 Shut Down Procedure',
				link: 'https://docs.google.com/presentation/d/1xxgt-GPLxmeJr3FoFMPTzAigaF9K--rCLI523wMtifc/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'Calendar',
				description: 'AM Calendar',
				link: 'https://docs.google.com/spreadsheets/d/1FWuB5NbWeIOduCUE3ylnu8Wrly56J24qtjdGyTMD70Y/edit#gid=668593593',
			},
			{
				type: 'CILR',
				description: 'Route Map',
				link: 'https://interactivetrainingsystems.com/whirlpool/Tulsa/Linear_Transfer_Automation/Eagle_1100_Ton_Press_with_Linear_Colt_AM_Route_Map_final.dwg',
			},
			{
				type: 'CILR',
				description: 'CILR Tags',
				link: 'https://interactivetrainingsystems.com/whirlpool/Tulsa/Linear_Transfer_Automation/CLIR_TAGS.pdf',
			},
		],
	},

	{
		name: 'Prab Scrap Conveyor',
		documents: [
			{
				type: 'Ledger',
				description: 'Prab Scrab Conveyor Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1Tt0AMcMXr26i4uJxiIJsal7q5_togsDHP9CLtPS3hlM/edit#gid=926335867',
			},
			{
				type: 'SMP',
				description: 'SMP Start Sheet',
				link: 'https://docs.google.com/presentation/d/12JIkZe-2d10QKbwR5_Ll5LxuMqK9lAcTsyl0vhQlCZ8/edit?ts=60d07f7e#slide=id.g39bfbdbb45_0_14',
			},
			{
				type: 'SOP',
				description: 'SOP Start Sheet',
				link: 'https://docs.google.com/presentation/d/1ho6D3zQ7Sgm68vLQ3jJVYgWMuFON_M5xY0PboJSFaRM/edit?ts=60d07f95#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP C01 - General Cleaning of Scrap Conveyor',
				link: 'https://docs.google.com/presentation/d/1YVs97cQwXlEsh5d-cauJpegkuIOvSM7VqOg0LjpEPJk/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP C02 - Cleaning of Drive Assembly',
				link: 'https://docs.google.com/presentation/d/1YIH7RV3JjdInpRhwAy0yOh39qgzJL0YBJi8wCl_FJA0/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP C03 - Cleaning of Tailshaft',
				link: 'https://docs.google.com/presentation/d/1UeYJm1MOg3L-qDWNVbDrBOCf1iNbeNSOOlwmqHe3zHA/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP C04 - Cleaning of Drive Motor',
				link: 'https://docs.google.com/presentation/d/1fn5-h3PRv2o7nMUgf7uPQ8btO2Ix5ZYg3_1Mf5WjBjs/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP I01 - General Inspection of Scrap Conveyor',
				link: 'https://docs.google.com/presentation/d/1IVcF4-fcfZEqsP7k2LmhLT5eV36OERcVw3ou7zFA6HA/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP I01 - Inspection of Drive Assembly',
				link: 'https://docs.google.com/presentation/d/18wL1sPvADOxFu0dnSGSKGei1VwAzyYwGJBfaB_m9uds/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP I02 - Inspection of Drive Assembly',
				link: 'https://docs.google.com/presentation/d/18wL1sPvADOxFu0dnSGSKGei1VwAzyYwGJBfaB_m9uds/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP I03 - Inspection of of Tailshaft',
				link: 'https://docs.google.com/presentation/d/1Cq-edKO3a-9xaabZnxSpRMtRnSSpEhhV4IZGvdVP61o/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP I04 - Inspection of Drive Motor/a>',
				link: 'https://docs.google.com/presentation/d/1vN2pmClimmMI7ysjb_zs-ROIcrnuaSxCrcz8bWVCe-M/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP L01 - Lubrication of Flange Bearings',
				link: 'https://docs.google.com/presentation/d/1m2-7PiFDG14j4VYqgVK3kYkuYkrYFFGylqY3ERwFHsE/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP L02 - Lubrication of 5HP Motor',
				link: 'https://docs.google.com/presentation/d/140G4k6fWQ9gNib3Tl5iqG4IWh4swEg37Kzn0kkotVuE/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP L03 - Lubrication of Gear Reducer',
				link: 'https://docs.google.com/presentation/d/1dZ_D1XryUZm-AXvJd4pd_8eqZJ4jG2JjJSH-_p-92p0/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'SOP',
				description: 'SOP L04 - Lubrication of Rotating Bearings',
				link: 'https://docs.google.com/presentation/d/169nhO3qPgyrXVdSkvjM6LRbUXMvbwDCN24bxxpnfK9U/edit#slide=id.g849f23443e_0_17',
			},
			{
				type: 'Calendar',
				description: 'AM Calendar',
				link: 'https://docs.google.com/spreadsheets/d/1TiZgQPjMvefB0Pvp0Pzn5_V2hNP-K1WDiognx2-hf6M/edit?ts=60d07fc4#gid=668593593',
			},
			{
				type: 'CILR',
				description: 'Route Map',
				link: 'https://interactivetrainingsystems.com/whirlpool/Tulsa/PRAB/PRAB_C19489BA-D00_AM_ROUTE_MAP.pdf',
			},
			{
				type: 'CILR',
				description: 'CILR Tags',
				link: 'https://interactivetrainingsystems.com/whirlpool/Tulsa/PRAB/CLIR_TAGS.pdf',
			},
		],
	},

	{
		name: 'Conveyor Vision Inspection System',
		documents: [
			{
				type: 'Ledger',
				description: 'Conveyor Vision Inspection System Ledger',
				link: 'https://docs.google.com/spreadsheets/d/11OezkqCXf1Tswnvd97WsjwboRJwXXFANFnp9dmgO8h4/edit#gid=1372949190',
			},
			{
				type: 'SMP',
				description: 'SMP-001-Cognex In-Sight D902M Camera Replacement',
				link: 'https://docs.google.com/presentation/d/119WdCjIRCYO85Wn7fO0aAL33l693ErWAHR9c4my9ERo/edit#slide=id.p3',
			},
			{
				type: 'SMP',
				description: 'SMP-002-LED Ring Replacement',
				link: 'https://docs.google.com/presentation/d/1um9-uEYC1ccqODRGCw-_kKV6CZ6v_-5NhJJsizevbZc/edit#slide=id.p3',
			},
			{
				type: 'SMP',
				description: 'SMP-003-Light Curtain Alignment',
				link: 'https://docs.google.com/presentation/d/1vD8fhjl1ImLouf4ovNN4Tui8YKqwhu22C_DqenG2UJg/edit#slide=id.p3',
			},
			{
				type: 'SMP',
				description: 'SMP-004-Guided Cylinder Replacement',
				link: 'https://docs.google.com/presentation/d/1lRuzuivhQ8kWw_umA_z7jxDtHlP_Q0QTAgcVuGCEVG4/edit#slide=id.p3',
			},
			{
				type: 'SMP',
				description: 'SMP-005-Vertical Transfer Belt Tensioning',
				link: 'https://docs.google.com/presentation/d/1rufuCRqDADXumax_HmHKWCbcK2sHLrAS1BUj3b1lygU/edit#slide=id.p3',
			},
			{
				type: 'SMP',
				description: 'SMP-006-Vertical Transfer Belt Replacement',
				link: 'https://docs.google.com/presentation/d/1jPOU_b74PDJI1xpJuMIWR-9czFS6OUGnuM1ttP-z8nw/edit#slide=id.p3',
			},
			{
				type: 'SMP',
				description: 'SMP-007-Lift and Transfer Belt Tensioning',
				link: 'https://docs.google.com/presentation/d/1KvY_6wAfDM-uqZUdpCUCNm6hzgSmqrXxxLGRoU4aGAY/edit#slide=id.p3',
			},
			{
				type: 'SMP',
				description: 'SMP-008-Lift and Transfer Belt Replacement',
				link: 'https://docs.google.com/presentation/d/18F4MEOBDDXgQznXc0pK_hrFIvpgrGnSadaR2_FOhdm8/edit#slide=id.p3',
			},
			{
				type: 'SMP',
				description: 'SMP-009-Vision Inspection Conveyor Belt Tensioning',
				link: 'https://docs.google.com/presentation/d/15KlRq9jY4GLaHBpvu3-kvrVVCDRsqDZ_uMqIFXCl6u0/edit#slide=id.p3',
			},
			{
				type: 'SMP',
				description: 'SMP-010-Vision Inspection Conveyor Belt Replacement',
				link: 'https://docs.google.com/presentation/d/1P8FlbAjhas437Gbs8eYZ_AjB2K-wVr1uV1UPbSzfZSw/edit#slide=id.p3',
			},
			{
				type: 'SMP',
				description: 'SMP-011-Tensioning and Greasing of Drive Chain',
				link: 'https://docs.google.com/presentation/d/1OJMdOOxTneSWk6RsHVlpXeBCw7U9D06szfyoovrT5tM/edit#slide=id.p3',
			},
			{
				type: 'SMP',
				description: 'SMP-012-Allen Bradley 1794-AENTR Communication Module Replacement',
				link: 'https://docs.google.com/presentation/d/1iiP5wAaxWo0LngVVVDTVmRW7Dgv-gtFZrQkfGPSg-zk/edit#slide=id.p3',
			},
			{
				type: 'SMP',
				description: 'SMP-013-Outfeed Conveyors Belt Tensioning',
				link: 'https://docs.google.com/presentation/d/1rd0nCiOqvrsUuzHzsDh1XyhAggCJYkOzEAuzxKj20UQ/edit#slide=id.p3',
			},
			{
				type: 'SMP',
				description: 'SMP-014-Outfeed Conveyor Belt Replacement',
				link: 'https://docs.google.com/presentation/d/1e3CLVoKJFKrbglJXuP_aIqIJzWixQ79lul8en4572dQ/edit#slide=id.p3',
			},

			{
				type: 'SOP',
				description: 'SOP C01-Cognex Camera Cleaning',
				link: 'https://docs.google.com/presentation/d/1KzzDH4agFr6Cyef4Vl2mFVwXWxBbD6uTDynIgc3dR4w/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP C02-Banner Light Screen Cleaning',
				link: 'https://docs.google.com/presentation/d/1nJuHe6NARG3n_Vrh1AEwYCNK-7tHlW0YzDvlD8fXFOs/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP C03-Lift and Transfer Cleaning',
				link: 'https://docs.google.com/presentation/d/1PVlnchE9pRlDA1f0DYk2dD0R48Ei6g0WuDa-DwJIgAk/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP C04-Vertical Transfer Cleaning',
				link: 'https://docs.google.com/presentation/d/11dzceSiPoJwl656KBgyIaW3CMuNzH2ZatdNECA2mPyk/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP C05-Timing Belt Conveyor Cleaning',
				link: 'https://docs.google.com/presentation/d/1XON_U3IhoNkpB0QRZ2HiJBsvD_ru4zpD8IqaqQRTQY4/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP C06-Main Control Panel Cleaning',
				link: 'https://docs.google.com/presentation/d/1iUONbNcybZr5GAnrNTknd7Q3L5_ys6ccwoF23a_jmnY/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP C07-HMI Panel Cleaning',
				link: 'https://docs.google.com/presentation/d/13KADWcdQBjpoCdhGgF2tb8KwzQ4UWBUX59tMiPVdWeY/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP C08-Outfeed Conveyors Cleaning',
				link: 'https://docs.google.com/presentation/d/1ixRaw4uNgBvqxCA4SwSEA9sntCVtsc_uVS5HLlyR_3s/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP I01-Cognex Camera Inspection',
				link: 'https://docs.google.com/presentation/d/1izgi2OYJhQkR0QnTtBvF2aF4kB_SdAShKjWQhPdTyyI/edit#slide=id.g13acece7745_0_0',
			},
			{
				type: 'SOP',
				description: 'SOP I02-Banner Light Screen Inspection',
				link: 'https://docs.google.com/presentation/d/1PSYaO2qShdOf7AvAL-sDtklIr1_BaJQng11KJ6LuIKI/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP I03-Guided Cylinder Inspection',
				link: 'https://docs.google.com/presentation/d/1yJcj1Nqq3P4krXb0D0G8-0w8RMnWxo9GIrYDMTsZSM0/edit#slide=id.g13aa459567f_0_0',
			},
			{
				type: 'SOP',
				description: 'SOP I04-Rough Locator Inspection',
				link: 'https://docs.google.com/presentation/d/1cZZ_jHVonDZK0bA2HEj0qGiBlhustbF32irgsGapREE/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP I05-Lift and Transfer Inspection',
				link: 'https://docs.google.com/presentation/d/1L6FuG8ROwL9Flf1NPj0grTyX0aAjqrSUB11zafmTBX4/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP I06-Vertical Transfer Inspection',
				link: 'https://docs.google.com/presentation/d/1rSWeHTgaYdt0Wj89tREXsIchSkpuoQgTLfbzKwA3h-Q/edit#slide=id.g13ad38a0cb6_0_0',
			},
			{
				type: 'SOP',
				description: 'SOP I07-Timing Belt Conveyor Inspection',
				link: 'https://docs.google.com/presentation/d/1KguW74Lz8cl1ZNLuZA6uCHXfoHxBRbuvaF-r0RVywag/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP I08-Main Control Panel Inspection',
				link: 'https://docs.google.com/presentation/d/1xhNf60KQbY6uf91yQoJSt1zykG8Wj6j3JHApLYSz_o8/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP I09-HMI Panel Inspection',
				link: 'https://docs.google.com/presentation/d/1g9pUGTO2NxPrbnMXrHM9GNSBpiYQuHQw-VhBU-7WWLM/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP I10-Outfeed Conveyors Inspection',
				link: 'https://docs.google.com/presentation/d/11dH0sQwm6bPjwFIHVQvhJW9JPOVoHhQ0ouBGcIThyYM/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP L01-Linear Bearing Lubrication',
				link: 'https://docs.google.com/presentation/d/1xTLKcQyHIA7duhbZpkjQ-OsLm_Bvch4Fog1Nbeo4ZWA/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP R01-Guided Cylinder Retighten',
				link: 'https://docs.google.com/presentation/d/1Q3lJVu1F6KmjaAkfPsqo0fFWags_BoLJ_Iqhi72ZNx8/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP R02-Rough Locator Retighten',
				link: 'https://docs.google.com/presentation/d/1ZUrmeNuC-VfEBMG4tumfBQvw0gmHVMA0sKtdAgiFgMQ/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP R03-Cognex Camera Retighten',
				link: 'https://docs.google.com/presentation/d/1L9XT4IoVETbImvrqQ_X4A-rv4-QvoPJ1i-vfMiQd8bo/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP R04-Light Curtain Retighten',
				link: 'https://docs.google.com/presentation/d/1ITTYwbtdYxQFDopocpG6aP362l-XH0w6NnBmHXFNk3U/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP S01-Allen Bradley Guard Locking Switch Safety Inspection',
				link: 'https://docs.google.com/presentation/d/1le-eQtf14prZQjcW6QFgLX590x0llSZXdXy_dDhZKP0/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP S02-Banner Light Screen Safety Inspection',
				link: 'https://docs.google.com/presentation/d/1IohJ2vkoUdbKXrD7ccqmRc8P7gOpzbIGrt6FZs8jqiI/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'Calendar',
				description: 'AM Calendar',
				link: 'https://docs.google.com/spreadsheets/d/1342pKpK9nXV2q8lwQRys8j2P0cGWDTiBl9-pzveICQY/edit#gid=405912428',
			},
			{
				type: 'CILR',
				description: 'Route Map',
				link: 'https://interactivetrainingsystems.com/whirlpool/Tulsa/Conveyor%20Vision%20Inspection%20System/Conveyor_Inspection_Route_Map.pdf',
			},
		],
	},

	{
		name: 'Umbrella Gasket 100 Line 4',
		documents: [
			{
				type: 'SOP',
				description: 'SOP C01-Keyence Vision Sensor Cleaning',
				link: 'https://docs.google.com/presentation/d/1SS5fmtE8pstifRMaeornH1QXb94EL8VGbPeOH6q6JnQ/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP C02-Keyence IV-3 HMI Cleaning',
				link: 'https://docs.google.com/presentation/d/1VvrWxSLE2VGUrALr-kZJPYQFvE9UoAklp_jEj6h4o1U/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP C03-Line 6 Main Assembly Cleaning',
				link: 'https://docs.google.com/presentation/d/1P72uuxjw3UXB8KYLDJIbD38beJfUtso1aNuBd8I2K_c/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP I01-Keyence Vision Sensor Inspection',
				link: 'https://docs.google.com/presentation/d/1LfU-fhCqzvcL9Qaz-0wsVBeUzq_DGK75l1p-L1h512o/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP I02-Keyence IV-3 HMI Inspection',
				link: 'https://docs.google.com/presentation/d/1Jq4IdBLY6WWtsKELjbR_JfrI52P3ChJ15VxD9XPJrUo/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP I03-Line 6 Main Assembly Inspection',
				link: 'https://docs.google.com/presentation/d/1g3AI4QGp66dgGJjXHiO8w8lt_TqMK1b9YBQ9JRNM_C8/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP I04-Part Support Inspection',
				link: 'https://docs.google.com/presentation/d/1scrnW-35vJ-auFasVJrXulLMrn8Hn84TMQrYE8TVT4g/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP L01-Linear Bearing Lubrication',
				link: 'https://docs.google.com/presentation/d/1qH0v7LnMK0TKrWH0GwRDOy7jDyChNvLB_QiRo4VjvjA/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP R01- Keyence Vision Sensor Retighten',
				link: 'https://docs.google.com/presentation/d/151lNVTWyZDtJVOpJbXgaAwwuR9HO_1qTsJ12rVWyhGA/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP R02- Part Support Retighten',
				link: 'https://docs.google.com/presentation/d/1E8qL28r33L0_QhgKdllSCf5jn_koC3iGXlQHVoDbCio/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP S01-Safety Check Estop',
				link: 'https://docs.google.com/presentation/d/1q1a-4Wyt6uno6Q1wwkUNs4lf3PTms9DkiE4n1sLO7Jk/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'Calendar',
				description: 'AM Calendar',
				link: 'https://docs.google.com/spreadsheets/d/1kXZQOYcv4hjejT7xkXDOG8FD5EGJ8iSqClQxAw8q59A/edit#gid=405912428',
			},
			{
				type: 'CILR',
				description: 'Route Map',
				link: 'https://interactivetrainingsystems.com/whirlpool/Tulsa/Umbrella%20Gasket%20100%20Line%204/Valve%20Stem%20Umbrella%20Gaskets%20Line%204%20Main%20Route%20Map.pdf',
			},
		],
	},

	{
		name: 'Umbrella Gasket 101 Line 6',
		documents: [
			{
				type: 'SOP',
				description: 'SOP C01-Keyence Vision Sensor Cleaning',
				link: 'https://docs.google.com/presentation/d/1SS5fmtE8pstifRMaeornH1QXb94EL8VGbPeOH6q6JnQ/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP C02-Keyence IV-3 HMI Cleaning',
				link: 'https://docs.google.com/presentation/d/1VvrWxSLE2VGUrALr-kZJPYQFvE9UoAklp_jEj6h4o1U/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP C03-Line 6 Main Assembly Cleaning',
				link: 'https://docs.google.com/presentation/d/1P72uuxjw3UXB8KYLDJIbD38beJfUtso1aNuBd8I2K_c/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP I01-Keyence Vision Sensor Inspection',
				link: 'https://docs.google.com/presentation/d/1LfU-fhCqzvcL9Qaz-0wsVBeUzq_DGK75l1p-L1h512o/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP I02-Keyence IV-3 HMI Inspection',
				link: 'https://docs.google.com/presentation/d/1Jq4IdBLY6WWtsKELjbR_JfrI52P3ChJ15VxD9XPJrUo/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP I03-Line 6 Main Assembly Inspection',
				link: 'https://docs.google.com/presentation/d/1g3AI4QGp66dgGJjXHiO8w8lt_TqMK1b9YBQ9JRNM_C8/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP L01-Linear Bearing Lubrication',
				link: 'https://docs.google.com/presentation/d/1qH0v7LnMK0TKrWH0GwRDOy7jDyChNvLB_QiRo4VjvjA/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP R01- Keyence Vision Sensor Retighten',
				link: 'https://docs.google.com/presentation/d/151lNVTWyZDtJVOpJbXgaAwwuR9HO_1qTsJ12rVWyhGA/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'SOP',
				description: 'SOP S01-Safety Check Estop',
				link: 'https://docs.google.com/presentation/d/1q1a-4Wyt6uno6Q1wwkUNs4lf3PTms9DkiE4n1sLO7Jk/edit#slide=id.g56637d5c48_0_9',
			},
			{
				type: 'Calendar',
				description: 'AM Calendar',
				link: 'https://docs.google.com/spreadsheets/d/1JAzieYR5PRpultjSqxsCvqxdjvonAbkrh8VwuDzfBWA/edit#gid=405912428',
			},
			{
				type: 'CILR',
				description: 'Route Map',
				link: 'https://interactivetrainingsystems.com/whirlpool/Tulsa/Umbrella%20Gasket%20101%20Line%206/Valve%20Stem%20Umbrella%20Gaskets%20Line%206%20Main%20Route%20Map.pdf',
			},
		],
	},
];

export default TulsaData;
