const FindlayData = [
	{
		name: 'Battenfeld Press 2',
		documents: [
			{
				description: 'Battenfeld Press 2',
				type: 'Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1ofRYf5oPQSyoJ5DHfEXK8ElERpxOPuUebKyHU_3cBw4/edit#gid=657824894',
			},
		],
	},
];

export default FindlayData;
