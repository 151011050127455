import { React, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Main, Container, Header, Footer } from '../../components/layout';
import { FaWrench, FaHammer, FaCalendarAlt, FaBookOpen } from 'react-icons/fa';
import { HiOutlineNewspaper } from 'react-icons/hi';
import homebg from '../assets/home-bg.jpg';
import ButtonList from './ButtonList';

const PageContentComp = ({ className, plant, data }) => {
	// search input
	const [search, setSearch] = useState('');

	// machine select inputs
	const [MachineFilter, setMachineFilter] = useState('');
	const [LedgerFilter, setLedgerFilter] = useState(false);
	const [SMPFilter, setSMPFilter] = useState(false);
	const [SOPFilter, setSOPFilter] = useState(false);
	const [SSDFilter, setSSDFilter] = useState(false);
	const [CILRFilter, setCILRFilter] = useState(false);
	const [ManualFilter, setManualFilter] = useState(false);
	const [LubeMapFilter, setLubeMapFilter] = useState(false);
	const [CalendarFilter, setCalendarFilter] = useState(false);

	// array of filtered objects
	const [FilterData, setFilterData] = useState([]);

	// array of active type filters
	const [TypeFilters, setTypeFilters] = useState([]);

	// fires every time you select a filter and toggles based on doc type
	const handleFilter = (docType, toggle) => {
		switch (docType) {
			case 'Ledger':
				// if button is inactive
				if (toggle === false) {
					// toggle it on
					setLedgerFilter(true);
					// add doc type to list of filter checks
					setTypeFilters((prev) => [...prev, 'Ledger']);

					// if button is active
				} else {
					// toggle it off
					setLedgerFilter(false);
					// remove doc type from list of filter checks
					setTypeFilters((prev) => prev.filter((item) => item !== docType));
				}
				break;
			case 'SMP':
				if (toggle === false) {
					setSMPFilter(true);
					setTypeFilters((prev) => [...prev, 'SMP']);
				} else {
					setSMPFilter(false);
					setTypeFilters((prev) => prev.filter((item) => item !== docType));
				}
				break;
			case 'SOP':
				if (toggle === false) {
					setSOPFilter(true);
					setTypeFilters((prev) => [...prev, 'SOP']);
				} else {
					setSOPFilter(false);
					setTypeFilters((prev) => prev.filter((item) => item !== docType));
				}
				break;
			case 'SSD':
				if (toggle === false) {
					setSSDFilter(true);
					setTypeFilters((prev) => [...prev, 'SSD']);
				} else {
					setSSDFilter(false);
					setTypeFilters((prev) => prev.filter((item) => item !== docType));
				}
				break;
			case 'CILR':
				if (toggle === false) {
					setCILRFilter(true);
					setTypeFilters((prev) => [...prev, 'CILR']);
				} else {
					setCILRFilter(false);
					setTypeFilters((prev) => prev.filter((item) => item !== docType));
				}
				break;
			case 'Manual':
				if (toggle === false) {
					setManualFilter(true);
					setTypeFilters((prev) => [...prev, 'Manual']);
				} else {
					setManualFilter(false);
					setTypeFilters((prev) => prev.filter((item) => item !== docType));
				}
				break;
			case 'LubeMap':
				if (toggle === false) {
					setLubeMapFilter(true);
					setTypeFilters((prev) => [...prev, 'LubeMap']);
				} else {
					setLubeMapFilter(false);
					setTypeFilters((prev) => prev.filter((item) => item !== docType));
				}
				break;
			case 'Calendar':
				if (toggle === false) {
					setCalendarFilter(true);
					setTypeFilters((prev) => [...prev, 'Calendar']);
				} else {
					setCalendarFilter(false);
					setTypeFilters((prev) => prev.filter((item) => item !== docType));
				}
				break;
			default:
				break;
		}
	};

	// fires any time data is changed on the page (either by user input or database fetches)
	useEffect(() => {
		// first grab machine from select input
		const MachineData = data.find((document) => document.name === MachineFilter);
		// if that data exists
		if (MachineData) {
			// filter it first with search input
			setFilterData(MachineData.documents.filter((document) => document.description.toLowerCase().includes(search)));
			// if any type filters are active
			if (TypeFilters.length > 0) {
				// filter it with both type filter and search input
				setFilterData(MachineData.documents.filter((document) => TypeFilters.includes(document.type) && document.description.toLowerCase().includes(search)));
			}
		}
	}, [MachineFilter, TypeFilters, search]);

	return (
		<>
			<Main>
				<Header />
				<Container>
					<mask style={{ backgroundImage: `url(${homebg})` }}></mask>
					<div className={`content ${className}`}>
						<left>
							<div id="machine-selector">
								<h4>Machine:</h4>
								<select
									value={MachineFilter}
									onChange={(e) => setMachineFilter(e.target.value)}
								>
									<option value="">-- select an option --</option>
									{data.map((machine) => {
										return <option value={machine.name}>{machine.name}</option>;
									})}
								</select>
							</div>
							<div className="document-selector">
								<div className={LedgerFilter === true ? 'filter-button toggled ledger-filter' : 'filter-button'}>
									<HiOutlineNewspaper
										size={60}
										style={{ color: '#000' }}
										onClick={() => handleFilter('Ledger', LedgerFilter)}
									/>
									<p>Machine Ledgers</p>
								</div>

								<div className={SMPFilter === true ? 'filter-button toggled smp-filter' : 'filter-button'}>
									<FaWrench
										size={60}
										style={{ color: '#000' }}
										onClick={() => handleFilter('SMP', SMPFilter)}
									/>
									<p>SMPs</p>
								</div>

								<div className={SOPFilter === true ? 'filter-button toggled sop-filter' : 'filter-button'}>
									<FaHammer
										size={60}
										style={{ color: '#000' }}
										onClick={() => handleFilter('SOP', SOPFilter)}
									/>
									<p>SOPs</p>
								</div>

								<div className={SSDFilter === true ? 'filter-button toggled ssd-filter' : 'filter-button'}>
									<FaBookOpen
										size={60}
										style={{ color: '#000' }}
										onClick={() => handleFilter('SSD', SSDFilter)}
									/>
									<p>Sub Supplier Docs</p>
								</div>

								<div className={ManualFilter === true ? 'filter-button toggled manual-filter' : 'filter-button'}>
									<HiOutlineNewspaper
										size={60}
										style={{ color: '#000' }}
										onClick={() => handleFilter('Manual', ManualFilter)}
									/>
									<p>Manuals</p>
								</div>

								<div className={CalendarFilter === true ? 'filter-button toggled calendar-filter' : 'filter-button'}>
									<FaCalendarAlt
										size={60}
										style={{ color: '#000' }}
										onClick={() => handleFilter('Calendar', CalendarFilter)}
									/>
									<p>AM Calendars</p>
								</div>

								<div className={CILRFilter === true ? 'filter-button toggled cilr-filter' : 'filter-button'}>
									<HiOutlineNewspaper
										size={60}
										style={{ color: '#000' }}
										onClick={() => handleFilter('CILR', CILRFilter)}
									/>
									<p>CILR Maps</p>
								</div>

								<div className={LubeMapFilter === true ? 'filter-button toggled lube-filter' : 'filter-button'}>
									<HiOutlineNewspaper
										size={60}
										style={{ color: '#000' }}
										onClick={() => handleFilter('LubeMap', LubeMapFilter)}
									/>
									<p>Lube Maps</p>
								</div>
							</div>
						</left>

						<right>
							<div className="doc-controls">
								{MachineFilter ? (
									<>
										<h1>{MachineFilter}</h1>
										<input
											value={search}
											onChange={(e) => setSearch(e.target.value)}
											type="text"
											placeholder="Search for documents..."
										/>
									</>
								) : (
									<h1>Whirlpool Corporation - {plant} Manufacturing</h1>
								)}
							</div>
							<div className="doc-list">{FilterData.length > 0 ? ButtonList(FilterData) : <h1>x</h1>}</div>
						</right>
					</div>
				</Container>
			</Main>
			<Footer />
		</>
	);
};

const PageContent = styled(PageContentComp)`
	position: absolute;
	display: flex;
	top: 0;
	width: 96vw;
	height: 74vh;
	justify-content: start;
	background: #090057;
	margin: 4vh 2vw;
	border-radius: 1rem;
	color: #000;

	& left {
		width: 21%;
		height: 70vh;
		background: lightgray;
		margin: 1vw;
		padding: 1vw;
		border: solid black;
		border-radius: 1rem;

		#machine-selector {
			display: flex;
			height: 5vh;

			& h4 {
				margin: auto;
			}

			& select {
				border-radius: 1rem;
				font-weight: 700;
				font-size: larger;
				margin: 0 10px;
				text-align: center;
				width: 12vw;
			}
		}

		& button {
			display: block;
		}

		.filter-button {
			width: 100%;
			text-align: center;
			margin: 10px 0;
			display: flex;

			& svg {
				background: grey;
				border-radius: 0.5rem;
				padding: 5px;

				&:hover {
					filter: brightness(1.2);
					-webkit-box-shadow: 0px 0px 13px 1px #000000;
					box-shadow: 0px 0px 5px 1px #000000;
				}
			}

			& p {
				margin: auto 20px;
				text-align: start;
				font-weight: 700;
				font-size: larger;
			}
		}

		.filter-button.toggled.ledger-filter {
			& svg {
				background: rgb(240, 183, 78);
			}
		}
		.filter-button.toggled.smp-filter {
			& svg {
				background: rgb(116, 200, 255);
			}
		}
		.filter-button.toggled.sop-filter {
			& svg {
				background: rgb(137, 255, 147);
			}
		}
		.filter-button.toggled.ssd-filter {
			& svg {
				background: rgb(160, 118, 168);
			}
		}
		.filter-button.toggled.cilr-filter {
			& svg {
				background: green;
			}
		}
		.filter-button.toggled.manual-filter {
			& svg {
				background: rgb(253, 255, 122);
			}
		}
		.filter-button.toggled.lube-filter {
			& svg {
				background: green;
			}
		}
		.filter-button.toggled.calendar-filter {
			& svg {
				background: rgb(245, 136, 136);
			}
		}
	}

	& right {
		position: relative;
		color: #000;
		width: 79%;
		height: 70vh;
		background: lightgray;
		margin: 1vw;
		border: solid black;
		border-radius: 1rem;
		overflow: auto;

		.doc-controls {
			text-align: center;
			display: flex;
			justify-content: space-between;
			border-bottom: solid var(--secondary) 5px;
			width: 71.4%;
			margin: 0 auto;
			background: lightgray;
			z-index: 10;
			position: fixed;
			border-top-right-radius: 1rem;
			border-top-left-radius: 1rem;
			padding: 10px;

			& h1 {
				font-weight: 700;
			}

			& input {
				border-radius: 1rem;
				width: 40%;
				padding: 5px;
			}
		}

		.doc-list {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			position: absolute;
			top: 8vh;
			width: 100%;

			& .doc-button {
				height: 20vh;
				width: 7vw;
				text-align: center;
				margin: 20px;
				& p {
					font-weight: 700;
				}
				& a {
					display: block;
					height: 10vh;
					border-radius: 1rem;
					margin: 10px;
					border: solid black;
				}

				& svg {
					padding: 5px;
					height: 100%;
					width: 100%;
					margin: auto;
					display: block;
				}

				.ledger-btn {
					background: rgb(240, 183, 78);
				}
				.smp-btn {
					background: rgb(116, 200, 255);
					padding: 10px;
				}
				.sop-btn {
					background: rgb(137, 255, 147);
					padding: 10px;
				}
				.manual-btn {
					background: rgb(253, 255, 122);
				}
				.supplier-btn {
					background: rgb(160, 118, 168);
				}
				.calendar-btn {
					background: rgb(245, 136, 136);
					padding: 10px;
				}

				&:hover {
					transform: scale(1.03);
					filter: brightness(1.1);
				}
			}
		}
	}

	@media screen and (max-width: 480px) {
		display: block;
		height: 81vh;
		width: 94vw;
		margin: 0 2vw;

		& left {
			display: block;
			width: 92vw;
			height: 22vh;

			#machine-selector {
				& select {
					width: 58vw;
				}
			}

			& .document-selector {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				height: 10vh;
				margin: 0;

				.filter-button {
					width: unset;
					margin: 10px;
					& svg {
						height: 5.5vh;
					}
				}

				& p {
					display: none;
				}
			}
		}

		& right {
			display: block;
			width: 92vw;
			height: 58vh;

			.doc-controls {
				width: 89.4%;
				position: relative;

				& h1 {
					font-size: 20px;
					margin: auto;
				}

				& select {
					width: 100vw;
				}

				& input {
					width: 60%;
				}
			}

			.doc-list {
				& .doc-button {
					width: 28vw;
					height: auto;
					margin: 0;
				}
			}
		}
	}
`;

export default PageContent;
