// dependencies
import { useState, useEffect, useRef } from 'react';
import GlobalStyles from './styles/GlobalStyles';
import Router from './Router';
import $ from 'jquery';
// tablesorter / bootstrap imports
import 'tablesorter';
import 'tablesorter/dist/js/extras/jquery.tablesorter.pager.min.js';
import 'tablesorter/dist/css/theme.ice.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
	return (
		<>
			<GlobalStyles />
			<Router />
		</>
	);
}

export default App;
