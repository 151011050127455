import { Route, Routes } from 'react-router-dom';

import Home from './pages/I - Home';
import Dashboard from './pages/II - Dashboard';
import ClydeData from './pages/-data-/ClydeData';
import ClevelandData from './pages/-data-/ClevelandData';
import FindlayData from './pages/-data-/FindlayData';
import OttawaData from './pages/-data-/OttawaData';
import MarionData from './pages/-data-/MarionData';
import TulsaData from './pages/-data-/TulsaData';

const Router = () => {
	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/clyde" element={<Dashboard plant="Clyde" data={ClydeData} />} />
			<Route path="/cleveland" element={<Dashboard plant="Cleveland" data={ClevelandData} />} />
			<Route path="/findlay" element={<Dashboard plant="Findlay" data={FindlayData} />} />
			<Route path="/ottawa" element={<Dashboard plant="Ottawa" data={OttawaData} />} />
			<Route path="/marion" element={<Dashboard plant="Marion" data={MarionData} />} />
			<Route path="/tulsa" element={<Dashboard plant="Tulsa" data={TulsaData} />} />
		</Routes>
	);
};

export default Router;
